import { getFormattedNumber } from "@msys/formatting";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  Autocomplete,
  autocompleteClasses,
  Button,
  FilledInput,
  filledInputClasses,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { identity, isNil, sortBy } from "lodash";
import { matchSorter } from "match-sorter";
import React from "react";
import { useLocale } from "../LocaleProvider";
import {
  PopoverOpenButton,
  PopoverOpenButtonRef,
} from "../popover/PopoverOpenButton";
import { SelectOption } from "./Select";

export const defaultFilterOptions = <Value,>(
  options: SelectOption<Value>[],
  { inputValue }: { inputValue: string }
): SelectOption<Value>[] => {
  const matchedOptions = matchSorter(options, inputValue, { keys: ["label"] });
  if (!isNil(options[0]?.counter)) {
    return matchedOptions.sort(
      (o1, o2) => (o2.counter ?? 0) - (o1.counter ?? 0)
    );
  }
  return matchedOptions;
};

interface Props<Value> {
  label: string;
  searchLabel: string;
  noOptionsLabel: string;
  options: SelectOption<Value>[];
  value: Value | null;
  onChange: (value: Value | null) => void;
  clearable?: boolean;
  disabled?: boolean;
  filterOptions?: (
    options: SelectOption<Value>[],
    { inputValue }: { inputValue: string }
  ) => SelectOption<Value>[];
  popoverWidth?: "md" | "lg" | "xl";
}

export const ButtonSelect = <Value,>(props: Props<Value>) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const popoverOpenButtonRef = React.useRef<PopoverOpenButtonRef>();
  return (
    <PopoverOpenButton
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      content={
        <ButtonSelectForm<Value>
          {...props}
          handleClose={() => popoverOpenButtonRef.current?.handleClose()}
        />
      }
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      popoverRef={popoverOpenButtonRef}
    >
      <Button
        size="extra-small"
        variant="text"
        color="primary"
        sx={{ textTransform: "none" }}
        endIcon={
          <KeyboardArrowDown
            sx={{
              transition: "transform 0.2s ease-out",
              ...(open ? { transform: `rotate(-180deg)` } : undefined),
            }}
          />
        }
        disabled={props.disabled}
      >
        {props.label}
        {props.value !== null ? " (1)" : ""}
      </Button>
    </PopoverOpenButton>
  );
};

const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    border: "none",
    margin: 0,
    marginRight: "-16px",
    color: "inherit",
    borderRadius: 0,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: "auto",
      alignItems: "flex-start",
      borderRadius: 0,
      // '&[aria-selected="true"]': {
      //   backgroundColor: "transparent",
      // },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}));

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

export function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

function ButtonSelectForm<Value>({
  searchLabel,
  noOptionsLabel,
  options,
  value,
  onChange,
  clearable,
  filterOptions = defaultFilterOptions,
  handleClose,
  popoverWidth = "md",
}: Props<Value> & { handleClose(): void }) {
  const locale = useLocale();
  const [inputValue, setInputValue] = React.useState<string>("");
  const _options = React.useMemo(
    () =>
      sortBy(filterOptions(options, { inputValue }), o =>
        o.value === value ? 0 : 1
      ),
    [filterOptions, inputValue, options, value]
  );
  return (
    <Stack
      sx={theme => ({
        width: `${theme.layout.filters.popoverWidth[popoverWidth]}px`,
      })}
      spacing={0}
      direction="column"
    >
      <Autocomplete
        open
        fullWidth
        size="extra-small"
        onClose={(event, reason) => {
          if (reason === "escape") {
            handleClose();
          }
        }}
        inputValue={inputValue}
        onInputChange={(_, value, reason) => {
          if (reason === "input") {
            setInputValue(value);
          }
        }}
        value={_options.find(o => o.value === value) ?? null}
        onChange={(event, newValue, reason) => {
          if (
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key === "Backspace" ||
              (event as React.KeyboardEvent).key === "Delete") &&
            reason === "removeOption"
          ) {
            return;
          }
          onChange(newValue?.value ?? null);
          if (newValue?.value) handleClose();
        }}
        filterOptions={identity}
        PopperComponent={PopperComponent}
        noOptionsText={noOptionsLabel}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            {option.icon || option.counter !== undefined || clearable ? (
              <Stack alignItems="center" direction="row" spacing={1} flex={1}>
                {option.icon}
                <Typography variant="body2" sx={{ flex: 1 }}>
                  {option.label}
                </Typography>
                {option.counter !== undefined ? (
                  <Typography variant="caption" sx={{ color: "grey.600" }}>
                    {getFormattedNumber(option.counter, locale)}
                  </Typography>
                ) : null}
                {clearable && option.value === value && (
                  <div
                    style={{
                      height: 0,
                      display: " flex",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      size="small"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        onChange(null);
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </div>
                )}
              </Stack>
            ) : (
              <Typography variant="body2">{option.label}</Typography>
            )}
          </li>
        )}
        options={_options}
        getOptionLabel={option => option.label}
        renderInput={params => (
          <FilledInput
            size="extra-small"
            type="search"
            fullWidth
            ref={params.InputProps.ref}
            inputProps={{ enterKeyHint: "search", ...params.inputProps }}
            autoFocus
            placeholder={`${searchLabel}…`}
            sx={{
              [`&.${filledInputClasses.root}`]: {
                padding: "0 !important",
              },
            }}
          />
        )}
        sx={theme => ({
          [`&.${autocompleteClasses.root}`]: {
            paddingX: 1,
            width: "100%",
            ...(options.length < 10
              ? { height: 0, overflow: "hidden" }
              : {
                  paddingY: 1,
                  borderBottom: `1px solid ${theme.palette.divider}`,
                }),
          },
        })}
      />
    </Stack>
  );
}
