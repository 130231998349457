import {
  BoldButton,
  HeadlineOneButton,
  HeadlineThreeButton,
  HeadlineTwoButton,
  ItalicButton,
  OrderedListButton,
  UnderlineButton,
  UnorderedListButton,
} from "@draft-js-plugins/buttons";
import type { EditorCommand } from "@draft-js-plugins/editor";
import Editor from "@draft-js-plugins/editor";
import createToolbarPlugin, {
  Separator,
} from "@draft-js-plugins/static-toolbar";
import "@draft-js-plugins/static-toolbar/lib/plugin.css";
import {
  TextField,
  TextFieldProps,
  InputBaseComponentProps,
} from "@mui/material";
import { DraftHandleValue, EditorState } from "draft-js";
import React, { useState } from "react";
import { makeStyles, withStyles } from "../styles";
import { blockRenderMap } from "./blockRenderMap";

// Draft.js integration with Material-UI, taken from: https://stackoverflow.com/questions/63056253/draft-js-integration-with-material-ui-throws-missing-ref-error

export function DraftEditor({
  label,
  editorState,
  onChange,
  handleKeyCommand,
  readOnly,
  forceShowToolbar = false,
  disabled = false,
  InputProps,
  onFocus,
  onBlur,
}: {
  label: string;
  editorState: EditorState;
  onChange: (value: EditorState) => void;
  handleKeyCommand?: (
    command: EditorCommand,
    editorState: EditorState,
    eventTimeStamp: number
  ) => DraftHandleValue;
  readOnly?: boolean;
  forceShowToolbar?: boolean;
  disabled?: boolean;
  InputProps?: TextFieldProps["InputProps"];
  onFocus?: InputBaseComponentProps["onFocus"];
  onBlur?: InputBaseComponentProps["onBlur"];
}) {
  const [focused, setFocused] = useState<boolean>(false);
  const editorRef = React.useRef(null);
  const contentState = editorState.getCurrentContent();
  const isNotEmpty =
    contentState.hasText() ||
    contentState.getBlockMap().first().getType() !== "unstyled";
  const showToolbar = forceShowToolbar || focused;

  const { classes } = useStyles();

  const [{ plugins, Toolbar }] = useState(() => {
    const toolbarPlugin = createToolbarPlugin({
      theme: {
        toolbarStyles: classes,
        buttonStyles: classes,
      },
    });
    const { Toolbar } = toolbarPlugin;
    const plugins = [toolbarPlugin];
    return { plugins, Toolbar };
  });

  return (
    <div style={{ position: "relative" }}>
      <StyledTextField
        $showToolbar={showToolbar}
        size="small"
        fullWidth
        label={label}
        InputProps={{
          ...InputProps,
          // @ts-ignore
          inputComponent: DraftField,
          inputProps: {
            ...InputProps?.inputProps,
            editorRef,
            editorState,
            handleOnChange: onChange,
            handleKeyCommand,
            readOnly,
            onFocus: e => {
              onFocus?.(e);
              setFocused(true);
            },
            onBlur: e => {
              onBlur?.(e);
              setFocused(false);
            },
            plugins,
            blockRenderMap,
          },
        }}
        InputLabelProps={{ shrink: isNotEmpty || focused }}
        disabled={disabled}
      />
      {showToolbar && (
        <Toolbar>
          {externalProps => (
            <>
              <HeadlineOneButton {...externalProps} />
              <HeadlineTwoButton {...externalProps} />
              <HeadlineThreeButton {...externalProps} />
              {/* @ts-ignore */}
              <Separator {...externalProps} />
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              {/* @ts-ignore */}
              <Separator {...externalProps} />
              <UnorderedListButton {...externalProps} />
              <OrderedListButton {...externalProps} />
            </>
          )}
        </Toolbar>
      )}
    </div>
  );
}

const StyledTextField = withStyles(
  ({
    $showToolbar,
    ...props
  }: React.ComponentProps<typeof TextField> & { $showToolbar: boolean }) => (
    <TextField {...props} />
  ),
  (theme, props) => ({
    root: {
      "& > .MuiInputBase-root": {
        fontWeight: "unset",
        lineHeight: "unset",
        letterSpacing: "unset",
      },
      "& > .MuiInputBase-root > .DraftEditor-root": {
        width: "100%",
        // top padding: 21px (from MUI) + 37px (from toolbar)
        padding: props.$showToolbar ? "58px 12px 4px" : "21px 12px 4px",
        lineHeight: "1.4375em",
      },
      "& .MuiInputLabel-root": {
        transform: props.$showToolbar
          ? "translate(12px, 49px) scale(1)"
          : "translate(12px, 14px) scale(1)",
      },
      "& .MuiInputLabel-root.MuiInputLabel-shrink": {
        transform: props.$showToolbar
          ? "translate(12px, 41px) scale(0.75)"
          : "translate(12px, 4px) scale(0.75)",
      },
    },
  })
);

interface InputElement {
  focus(): void;
  value?: string;
}
interface DraftFieldProps extends React.ComponentProps<typeof Editor> {
  editorRef: React.RefObject<Editor>;
  handleOnChange: (editorState: EditorState) => void;
}
const DraftField = React.forwardRef<InputElement, DraftFieldProps>(
  function DraftField(props, ref) {
    const { editorRef, handleOnChange, ...rest } = props;

    React.useImperativeHandle(ref, () => ({
      focus: () => {
        editorRef?.current?.focus();
      },
    }));

    return <Editor {...rest} ref={editorRef} onChange={handleOnChange} />;
  }
);

const useStyles = makeStyles()({
  toolbar: {
    zIndex: 2,
    boxSizing: "border-box",
    position: "absolute",
    top: "6px",
    left: "6px",
    right: "6px",
  },
  buttonWrapper: {
    display: "inline-block",
  },
  button: {
    background: "transparent",
    color: "#afcde6",
    fontSize: "18px",
    fontWeight: 600,
    border: 0,
    borderRadius: "4px",
    padding: 0,
    paddingTop: "3px",
    verticalAlign: "bottom",
    textAlign: "center",
    height: "34px",
    width: "36px",
    cursor: "pointer",
    "& svg": {
      marginTop: "3px",
      fill: "#afcde6",
    },
    "&:hover, &:focus": {
      background: "#c8dceb",
      outline: 0 /* reset for :focus */,
    },
  },
  active: {
    color: "#377dc3",
    "& svg": {
      fill: "#377dc3",
    },
  },
});
