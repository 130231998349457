import { IconButton } from "@mui/material";
import React from "react";
import { VisibilityIndicatorIcon } from "./VisibilityIndicatorIcon";

type Props = {
  isVisible: boolean;
  onChange: (isVisible: boolean) => void;
  disabled?: boolean;
  color?: React.ComponentProps<typeof IconButton>["color"] &
    React.ComponentProps<typeof VisibilityIndicatorIcon>["color"];
  ariaLabel?: React.ComponentProps<typeof IconButton>["aria-label"];
  style?: React.ComponentProps<typeof IconButton>["style"];
  fontSize?: React.ComponentProps<typeof VisibilityIndicatorIcon>["fontSize"];
};

export const VisibilityButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      isVisible,
      color = "secondary",
      ariaLabel,
      disabled = false,
      style,
      onChange,
      fontSize,
    },
    forwardedRef
  ) => {
    return (
      <IconButton
        ref={forwardedRef}
        size="small"
        color={color}
        aria-label={ariaLabel}
        disabled={disabled}
        style={style}
        onClick={e => {
          e.stopPropagation();
          onChange(!isVisible);
        }}
      >
        <VisibilityIndicatorIcon
          isVisible={isVisible}
          fontSize={fontSize}
          padding={0}
          color={disabled ? undefined : color}
        />
      </IconButton>
    );
  }
);
