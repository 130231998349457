import React, { ChangeEvent, ReactElement } from "react";
import padStart from "lodash.padstart";

const COLON = ":";
const VALUE_SHORT = `00${COLON}00`;

const isNumber = (value: any): boolean => {
  const number = Number(value);
  return !isNaN(number) && String(value) === String(number);
};

const formatTimeItem = (value?: string | number): string =>
  padStart((+(value || "")).toString(), 2, "0");

export function validateTimeAndCursor(
  value = "",
  defaultValue = "",
  colon = COLON,
  cursorPosition = 0
): [string, number] {
  const [oldH, oldM] = defaultValue.split(colon);

  let newCursorPosition = Number(cursorPosition);
  let [newH, newM] = String(value).split(colon);

  newH = formatTimeItem(newH);
  if (Number(newH[0]) > 2) {
    newH = oldH;
    newCursorPosition -= 1;
  } else if (Number(newH[0]) === 2) {
    if (Number(oldH[0]) === 2 && Number(newH[1]) > 3) {
      newH = `2${oldH[1]}`;
      newCursorPosition -= 2;
    } else if (Number(newH[1]) > 3) {
      newH = "23";
    }
  }

  newM = formatTimeItem(newM);
  if (Number(newM[0]) > 5) {
    newM = oldM;
    newCursorPosition -= 1;
  }

  const validatedValue = `${newH}${colon}${newM}`;

  return [validatedValue, newCursorPosition];
}

type onChangeType = (
  event: ChangeEvent<HTMLInputElement>,
  value: string
) => void;

interface Props {
  value?: string;
  onChange?: onChangeType;
  input: ReactElement | null;
  inputRef?: () => HTMLInputElement | null;
  colon?: string;
  enabled?: boolean;
}

interface State {
  value: string;
  _colon: string;
  _defaultValue: string;
  _maxLength: number;
}

export class DurationDailyInput extends React.Component<Props, State> {
  static defaultProps: Props = {
    enabled: true,
    input: null,
    colon: COLON,
  };

  constructor(props: Props) {
    super(props);

    const _defaultValue = VALUE_SHORT;
    const _colon =
      props.colon && props.colon.length === 1 ? props.colon : COLON;
    if (!props.enabled) {
      this.state = {
        value: props.value || "",
        _colon,
        _defaultValue,
        _maxLength: _defaultValue.length,
      };
    } else {
      const [validatedTime] = validateTimeAndCursor(
        props.value,
        _defaultValue,
        _colon
      );

      this.state = {
        value: validatedTime,
        _colon,
        _defaultValue,
        _maxLength: _defaultValue.length,
      };
    }

    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidUpdate(prevProps: Props): void {
    if (this.props.value !== prevProps.value) {
      if (!this.props.enabled) {
        this.setState({
          value: this.props.value || "",
        });
      } else {
        const [validatedTime] = validateTimeAndCursor(
          this.props.value,
          this.state._defaultValue,
          this.state._colon
        );
        this.setState({
          value: validatedTime,
        });
      }
    }
  }

  onInputChange(
    event: ChangeEvent<HTMLInputElement>,
    callback: onChangeType
  ): void {
    const oldValue = this.state.value;
    const inputEl = event.target;
    const inputValue = inputEl.value;
    const position = inputEl.selectionEnd || 0;
    const isTyped = inputValue.length > oldValue.length;
    const cursorCharacter = inputValue[position - 1];
    const addedCharacter = isTyped ? cursorCharacter : null;
    const removedCharacter = isTyped ? null : oldValue[position];
    const replacedSingleCharacter =
      inputValue.length === oldValue.length ? oldValue[position - 1] : null;
    const colon = this.state._colon;

    let newValue = oldValue;
    let newPosition = position;

    if (addedCharacter !== null) {
      if (position > this.state._maxLength) {
        newPosition = this.state._maxLength;
      } else if (position === 3 && addedCharacter === colon) {
        newValue = `${inputValue.substr(0, position - 1)}${colon}${inputValue.substr(position + 1)}`; /* prettier-ignore */
      } else if (position === 3 && isNumber(addedCharacter)) {
        newValue = `${inputValue.substr(0, position - 1)}${colon}${addedCharacter}${inputValue.substr(position + 2)}`; /* prettier-ignore */
        newPosition = position + 1;
      } else if (isNumber(addedCharacter)) {
        if (position === 1 && oldValue[0] === "0" && +addedCharacter > 2) {
          newValue = `0${addedCharacter}${colon}${inputValue.substr(position + 2)}`; /* prettier-ignore */
          newPosition = position + 2;
        } else {
          // user typed a number
          newValue = `${inputValue.substr(0, position - 1)}${addedCharacter}${inputValue.substr(position + 1)}`; /* prettier-ignore */
          if (position === 2) {
            newPosition = position + 1;
          }
        }
      } else {
        // if user typed NOT a number, then keep old value & position
        newPosition = position - 1;
      }
    } else if (replacedSingleCharacter !== null) {
      // user replaced only a single character
      if (isNumber(cursorCharacter)) {
        if (position - 1 === 2) {
          newValue = `${inputValue.substr(0, position - 1)}${colon}${inputValue.substr(position)}`; /* prettier-ignore */
        } else {
          newValue = inputValue;
        }
      } else {
        // user replaced a number on some non-number character
        newValue = oldValue;
        newPosition = position - 1;
      }
    } else if (
      typeof cursorCharacter !== "undefined" &&
      cursorCharacter !== colon &&
      !isNumber(cursorCharacter)
    ) {
      // set of characters replaced by non-number
      newValue = oldValue;
      newPosition = position - 1;
    } else if (removedCharacter !== null) {
      if (position === 2 && removedCharacter === colon) {
        newValue = `${inputValue.substr(0, position - 1)}0${colon}${inputValue.substr(position)}`; /* prettier-ignore */
        newPosition = position - 1;
      } else {
        // user removed a number
        newValue = `${inputValue.substr(0, position)}0${inputValue.substr(position)}`; /* prettier-ignore */
      }
    }

    const [validatedTime, validatedCursorPosition] = validateTimeAndCursor(
      newValue,
      oldValue,
      colon,
      newPosition
    );

    this.setState({ value: validatedTime }, () => {
      inputEl.selectionStart = validatedCursorPosition;
      inputEl.selectionEnd = validatedCursorPosition;
      callback(event, validatedTime);
    });

    event.persist();
  }

  render() {
    const { value } = this.state;
    const { onChange, input, inputRef, colon, enabled, ...props } = this.props; //eslint-disable-line @typescript-eslint/no-unused-vars
    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
      this.onInputChange(
        event,
        (e: ChangeEvent<HTMLInputElement>, v: string) =>
          onChange && onChange(e, v)
      );

    if (input) {
      return React.cloneElement(input, {
        ...props,
        value,
        onChange: onChangeHandler,
      });
    }

    return (
      <input
        type="text"
        {...props}
        ref={inputRef}
        value={value}
        onChange={onChangeHandler}
      />
    );
  }
}
