import { Typography } from "@mui/material";
import React from "react";

interface Props {
  color?: string;
  small?: boolean;
  style?: React.CSSProperties;
  align?: React.ComponentProps<typeof Typography>["align"];
  component?: "div" | "span";
}

export const StatusLabel = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<Props>
>(({ children, style, color, small = false, ...props }, ref) => (
  <Typography
    component="div"
    sx={{
      ...style,
      ...(color ? { color } : undefined),
      textTransform: "uppercase",
      fontSize: small ? "12px" : "14px",
      fontWeight: 500,
    }}
    ref={ref}
    {...props}
  >
    {children}
  </Typography>
));

export default StatusLabel;
