import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Box, SvgIconProps } from "@mui/material";
import React from "react";

export const CollapseIcon = ({
  isCollapsed,
  color = "primary",
}: {
  isCollapsed: boolean;
  color?: SvgIconProps["color"];
}) => {
  return (
    <Box height="1.5rem" width="1.5rem">
      {isCollapsed ? (
        <ArrowRightIcon color={color} />
      ) : (
        <ArrowDropDownIcon color={color} />
      )}
    </Box>
  );
};
