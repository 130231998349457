import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box,
  IconButton,
  Select,
  Tab,
  Tabs as MuiTabs,
  TabProps as MuiTabProps,
} from "@mui/material";
import React from "react";
import { CollapseChip } from "./CollapseChip";
import { useScreenWidth } from "./hooks/useScreenWidth";
import { withStyles } from "./styles";

export interface Option<T> {
  icon?: React.ComponentProps<typeof Tab>["icon"];
  count?: number;
  label: string;
  value: T;
  disabled?: boolean;
}

export interface TabsProps<T, O extends Option<T>> {
  /**
   * On change callback
   */
  onChange: (newValue: T) => void;
  /**
   * Selected value
   */
  value: T | undefined;
  /**
   * Available options
   */
  options: O[];
  /**
   * Whether to always show select
   */
  useSelect?: boolean;
  /**
   * Whether to show select on mobile devices
   */
  useSelectOnMobile?: boolean;
  /**
   * Whether tabs are condensed
   */
  condensed?: boolean;
  onDeselect?: () => void;
  notSelectedTitle?: string;
  tabProps?: (option: O, index: number) => Partial<MuiTabProps>;
  filterSelectOption?: (option: O, index: number) => boolean;
}

const defaultFilterSelectOption = () => true;

export const Tabs = <T, O extends Option<T>>({
  value,
  onChange,
  options,
  condensed = false,
  useSelect = false,
  useSelectOnMobile = false,
  notSelectedTitle = "Not selected",
  onDeselect,
  tabProps,
  filterSelectOption = defaultFilterSelectOption,
}: TabsProps<T, O>) => {
  const { isMaxPhone } = useScreenWidth();
  return useSelect || (isMaxPhone && useSelectOnMobile) ? (
    <Select
      fullWidth={false}
      native
      variant="outlined"
      size="extra-small"
      value={value || ""}
      onChange={event => {
        const option = options.find(o => o.value === event.target.value);
        if (option !== undefined) {
          // FIXME: problem when multiple options with same label, but that should never happen anyways as it's very confusing for the user
          onChange(option.value);
        } else if (option === undefined && onDeselect) {
          onDeselect();
        }
      }}
    >
      {onDeselect ? (
        <option key="not-selected" value="">
          {notSelectedTitle}
        </option>
      ) : null}
      {options.filter(filterSelectOption).map((option, index) => (
        // FIXME: problem when multiple options with same label, but that should never happen anyways as it's very confusing for the user
        <option key={index} value={option.value as unknown as string}>
          {option.label}
          {option.count !== undefined ? ` (${option.count})` : ""}
        </option>
      ))}
    </Select>
  ) : (
    <MuiTabs
      value={value ?? false}
      onChange={(_e, newValue: T) => onChange(newValue)}
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
      ScrollButtonComponent={ScrollButton}
      sx={theme => ({
        minHeight: "unset",
        [".MuiTab-root"]: {
          minWidth: "unset",
          minHeight: "unset",
          transition: "background-color 0.1s ease-out",
          padding: condensed ? "6px 6px" : "6px 12px",
          borderRadius: `${theme.shape.borderRadius}px`,
          ["&:hover:not(.Mui-selected)"]: {
            backgroundColor: theme.palette.action.hover,
          },
          ["&:active:not(.Mui-selected)"]: {
            backgroundColor: theme.palette.action.selected,
          },
          ["&.Mui-selected"]: {
            color: theme.palette.primary.main,
            ...(!onDeselect ? { cursor: "default" } : undefined),
          },
          ["&.Mui-disabled"]: {
            cursor: "default",
          },
          [".MuiSvgIcon-root"]: {
            mr: 0.5,
            fontSize: "1.25rem",
          },
        },
      })}
    >
      {options.map((option, index) => (
        <Tab
          key={index}
          disabled={(!onDeselect && value === option.value) || option.disabled}
          value={option.value}
          label={
            <>
              {option.label}
              {option.count !== undefined && (
                <Box
                  display="flex"
                  alignItems="center"
                  ml={0.5}
                  component="span"
                  height="1em"
                >
                  <CollapseChip label={option.count} />
                </Box>
              )}
            </>
          }
          icon={option.icon}
          iconPosition="start"
          onClick={
            onDeselect && value === option.value ? onDeselect : undefined
          }
          {...tabProps?.(option, index)}
        />
      ))}
    </MuiTabs>
  );
};

function ScrollButton(props: {
  direction: "left" | "right";
  visible: boolean;
}) {
  if (props.direction === "left") {
    return (
      <IconScrollButton
        color="primary"
        disabled={!props.visible}
        size="small"
        {...props}
      >
        <ChevronLeftIcon />
      </IconScrollButton>
    );
  } else if (props.direction === "right") {
    return (
      <IconScrollButton
        color="primary"
        disabled={!props.visible}
        size="small"
        {...props}
      >
        <ChevronRightIcon />
      </IconScrollButton>
    );
  } else {
    return null;
  }
}

const IconScrollButton = withStyles(IconButton, {
  root: {
    width: "1.5rem",
    height: "1.5rem",
    flexShrink: 0,
    flexGrow: 0,
    alignSelf: "center",
  },
});
