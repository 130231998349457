export type SystemLocale = "en" | "de" | "fr" | "ru" | "pl" | "nl";

export type Locale = "en-GB" | "de" | "fr" | "ru" | "pl" | "nl";

export const defaultLocale: Locale = "en-GB";

export const getSupportedSystemLocalCode = (
  localeOrSubLocale: string
): SystemLocale => {
  const localCode = localeOrSubLocale.split(/[-_]/)[0];
  if (["en", "de", "fr", "ru", "pl", "nl"].includes(localCode))
    return localCode as SystemLocale;
  // nothing found
  return "en";
};

export const getSupportedSystemLocale = (localeOrSubLocale: string): Locale => {
  const localeCode = getSupportedSystemLocalCode(localeOrSubLocale);

  if (localeCode === "en") return "en-GB";
  if (localeCode === "de") return "de";
  if (localeCode === "fr") return "fr";
  if (localeCode === "ru") return "ru";
  if (localeCode === "pl") return "pl";
  if (localeCode === "nl") return "nl";

  // nothing found
  return defaultLocale;
};
