import { styled } from "@mui/material/styles";
import React from "react";

export const ellipsisStyle = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
} as const;

export const noWrapStyle = {
  whiteSpace: "nowrap",
} as const;

export const Ellipsis: React.ComponentType<
  React.HTMLAttributes<HTMLSpanElement>
> = styled("span")({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});
