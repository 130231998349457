import { getImageUrl } from "@msys/common";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/GetApp";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import {
  CircularProgress,
  IconButton,
  ImageListItemBar,
  Link,
  Stack,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useGesture } from "@use-gesture/react";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { animated, useSprings } from "react-spring";
import { useLatest } from "react-use";
import {
  Attachment3dModel,
  AttachmentImage,
  is3dModel,
  isImage,
} from "../attachments/helpers";
import {
  useIconButtonBlueStyles,
  useIconButtonDarkStyles,
  useIconButtonLightStyles,
} from "../button/useIconButtonStyles";
import { useDownloadFile } from "../hooks/useDownloadFile";
import { useScreenWidth } from "../hooks/useScreenWidth";
import { MenuButton } from "../menu/MenuButton";
import { MenuItemWithIcon } from "../menu/MenuItemWithIcon";
import { transientOptions } from "../styles";
import { ThreeDViewer } from "../threeD/ThreeDViewer";

const clamp = (input: number, lower: number, upper: number) =>
  Math.min(Math.max(input, lower), upper);

export interface GallerySliderProps<
  I extends AttachmentImage | Attachment3dModel,
> {
  images: I[];
  slideIndex?: number;
  onSlideIndexChange?: (index: number) => void;
  autoScrollMs?: number;
  hasArrows?: boolean;
  hasBullets?: boolean;
  slidesAtOnce?: number;
  slidesToSlide?: number;
  handleAdd?: () => void;
  handleDelete?: (image: I) => void;
  handleRotate?: (image: I, direction: "right" | "left") => void;
  handleClick?: (index: number, image: I) => void;
  showDownloadButton?: boolean;
  showImageName?: boolean;
  disabled?: boolean;
  width?: React.CSSProperties["width"];
  height?: React.CSSProperties["height"];
  style?: React.CSSProperties;

  downloadTitle?: string;
  addTitle?: string;
  deleteTitle?: string;
  rotateTitle?: string;

  threeDInteractive?: boolean;
  arButtonTitle?: string;
}

// Based on: https://github.com/farbenmeer/react-spring-slider/blob/main/src/index.tsx
export const GallerySlider = <I extends AttachmentImage | Attachment3dModel>({
  images,
  slideIndex,
  onSlideIndexChange,
  autoScrollMs = 0,
  hasArrows = true,
  hasBullets = false,
  slidesAtOnce = 1,
  slidesToSlide = 1,
  handleClick,
  handleAdd,
  handleRotate,
  handleDelete,
  showDownloadButton = true,
  showImageName = true,
  disabled = false,
  width,
  height,
  style,
  downloadTitle,
  addTitle,
  deleteTitle,
  rotateTitle,
  threeDInteractive = false,
  arButtonTitle,
}: GallerySliderProps<I>) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [slide, setSlide] = useState<number>(slideIndex ?? 0);
  const isDraggingRef = useRef<boolean>(false);
  const onChangeLatest = useLatest(onSlideIndexChange);

  // Initialize slides with spring
  const [springProps, springPropsRef] = useSprings<{ offset: number }>(
    images.length,
    (index: number) => ({
      offset: index - slide,
    })
  );

  const currentImage: I | undefined = images[slide % images.length];

  // Bindings to set on the element
  const gestureBinds = useGesture(
    {
      onDrag: state => {
        const {
          down,
          // @ts-ignore FIXME typescript fail on check
          movement: [xDelta, yDelta],
          // @ts-ignore FIXME typescript fail on check
          direction: [xDir],
          // @ts-ignore FIXME typescript fail on check
          distance: [xDistance, yDistance],
          // @ts-ignore FIXME typescript fail on check
          cancel,
          // @ts-ignore FIXME typescript fail on check
          active,
        } = state;
        if (
          !isDraggingRef.current &&
          (Math.abs(xDelta) > 10 || Math.abs(yDelta) > 10)
        ) {
          isDraggingRef.current = true;
        }
        if (sliderRef.current?.parentElement) {
          const { width } =
            sliderRef.current.parentElement.getBoundingClientRect();

          if (down && Math.abs(xDistance) > width / 4) {
            if (cancel) cancel();
            if (active) {
              setSlide(
                clamp(
                  slide + (xDir > 0 ? -1 : 1),
                  0,
                  images.length - slidesAtOnce
                )
              );
            }
          }

          // see:  https://github.com/react-spring/react-spring/issues/861
          // @ts-ignore
          springPropsRef
            .update(index => ({
              offset: (active && down ? xDelta : 0) / width + (index - slide),
            }))
            .start();
        }
      },
      onClick: ({ event }) => {
        if (isDraggingRef.current) {
          isDraggingRef.current = false;
          return;
        }
        // // Calls onClick on the current slide
        const index = slide % images.length;
        handleClick?.(index, images[index]);
      },
    },
    {
      drag: {
        delay: 50,
        enabled: Boolean(currentImage && isImage(currentImage)),
      },
    }
  );

  // Triggered on slide change
  useEffect(() => {
    // see:  https://github.com/react-spring/react-spring/issues/861
    // @ts-ignore
    springPropsRef.update(index => ({ offset: index - slide })).start();
    onChangeLatest.current?.(slide);
  }, [slide, springPropsRef, onChangeLatest]);

  // Effect for auto-sliding
  useEffect(() => {
    let interval: ReturnType<typeof setTimeout>;
    if (autoScrollMs > 0) {
      interval = setTimeout(() => {
        const targetIndex = (slide + 1) % images.length;
        setSlide(targetIndex);
      }, autoScrollMs);
    }
    return () => {
      if (interval) clearTimeout(interval);
    };
  }, [autoScrollMs, images.length, slide]);

  // Jump to slide index when prop changes
  useEffect(() => {
    if (slideIndex !== undefined) setSlide(slideIndex % images.length);
  }, [slideIndex, images.length]);

  // Sets pointer events none to every child and preserves styles
  const imagesToRender = images.map((image, index) => (
    <StyledImageWrapper
      key={index}
      // We use onClick callback from useGesture
      // onClick={handleClick ? () => handleClick(index, image) : undefined}
      role={handleClick ? "button" : "initial"}
      $clickable={Boolean(handleClick)}
    >
      {isImage(image) ? (
        <StyledPicture
          draggable={false}
          src={getImageUrl({ url: image.url })}
          alt={image.title}
        />
      ) : is3dModel(image) ? (
        <ThreeDViewer
          modelUrl={image.url}
          cameraControls={threeDInteractive}
          ar={threeDInteractive}
          autoRotate={threeDInteractive}
          arButtonTitle={arButtonTitle}
          fullScreen={false}
          paddingBottom={showImageName ? 48 : 0} // we need this padding to show AR button above ImageListItemBar
        />
      ) : null}

      {showImageName && (
        <ImageListItemBar
          sx={{
            background: theme =>
              theme.palette.mode === "dark"
                ? `rgba(0, 0, 0, 0.5)`
                : `rgba(80, 135, 195, 0.35)`,
          }}
          /* eslint-disable-next-line no-irregular-whitespace */
          title={`${index + 1}/${images.length}   ${image.title}`}
          position="bottom"
        />
      )}
    </StyledImageWrapper>
  ));

  const goToFirstSlide = () => {
    setSlide(0);
  };

  const goToLastSlide = () => {
    setSlide(images.length - slidesAtOnce);
  };

  const nextSlide = () => {
    const reachedLastSlide = slide === images.length - slidesAtOnce;
    const nextSlideExists =
      slide + (slidesAtOnce - 1) + slidesToSlide < images.length - 1;
    if (reachedLastSlide) {
      goToFirstSlide();
    } else if (!nextSlideExists) {
      goToLastSlide();
    } else {
      setSlide(slide + slidesToSlide);
    }
  };

  const previousSlide = () => {
    if (slide === 0) {
      goToLastSlide();
      return;
    } else if (slide - slidesToSlide <= 0) {
      goToFirstSlide();
      return;
    } else {
      setSlide(slide - slidesToSlide);
    }
  };

  // TODO
  // const bullets = () => {
  //   const array = [];
  //   for (let index = 0; index <= images.length - slidesAtOnce; index++) {
  //     array.push(
  //       <BulletComponent
  //         key={index}
  //         isActive={index === slide}
  //         onClick={() => setSlide(index)}
  //         style={bulletStyle}
  //       />
  //     );
  //   }
  //   return array;
  // };

  return (
    <StyledWrapper
      onMouseDown={e => {
        e.stopPropagation();
      }}
      onTouchStart={e => {
        e.stopPropagation();
      }}
      // onClick={e => {
      //   e.preventDefault();
      //   e.stopPropagation();
      // }}
      $width={width}
      $height={height}
      ref={sliderRef}
      style={style}
    >
      {hasArrows && images.length > 1 && (
        <>
          <NavigationButtonContainer $prev={true} $next={false}>
            <NavigationButton
              prev={true}
              next={false}
              onClick={previousSlide}
            />
          </NavigationButtonContainer>
          <NavigationButtonContainer $prev={false} $next={true}>
            <NavigationButton prev={false} next={true} onClick={nextSlide} />
          </NavigationButtonContainer>
        </>
      )}

      {/* TODO */}
      {/*{hasBullets && images.length > 1 && (*/}
      {/*  <BulletsComponent>*/}
      {/*    <StyledBulletList>{bullets()}</StyledBulletList>*/}
      {/*  </BulletsComponent>*/}
      {/*)}*/}

      {springProps.map(({ offset }, index) => (
        <animated.div
          {...gestureBinds()}
          key={index}
          style={{
            // @ts-ignore weird typing issue
            transform: offset.to(
              // @ts-ignore weird typing issue
              offsetX => `translate3d(${offsetX * 100}%, 0, 0)`
            ),
            position: "absolute",
            width: `${100 / slidesAtOnce}%`,
            height: "100%",
            willChange: "transform",
            touchAction: "none",
          }}
        >
          {imagesToRender[index]}
        </animated.div>
      ))}

      <Stack
        direction="row"
        spacing={1}
        sx={{
          position: "absolute",
          right: "8px",
          top: "8px",
          zIndex: 2,
        }}
      >
        <ActionButtons
          currentImage={currentImage}
          showDownloadButton={showDownloadButton}
          handleAdd={
            handleAdd
              ? e => {
                  e.stopPropagation();
                  handleAdd();
                }
              : undefined
          }
          handleRotate={
            handleRotate && currentImage && isImage(currentImage)
              ? e => {
                  e.stopPropagation();
                  handleRotate(currentImage, "right");
                }
              : undefined
          }
          handleDelete={
            handleDelete && currentImage
              ? e => {
                  e.stopPropagation();
                  handleDelete(currentImage);
                }
              : undefined
          }
          disabled={disabled}
          downloadTitle={downloadTitle}
          addTitle={addTitle}
          deleteTitle={deleteTitle}
          rotateTitle={rotateTitle}
        />
      </Stack>
    </StyledWrapper>
  );
};

const NavigationButtonContainer = styled(
  "div",
  transientOptions
)<{ $next: boolean; $prev: boolean }>(({ $next, $prev }) => ({
  top: "50%",
  transform: "translateY(-50%)",
  position: "absolute",
  zIndex: 2,
  display: "flex",
  ...($next ? { right: "8px" } : undefined),
  ...($prev ? { left: "8px" } : undefined),
}));

const NavigationButton = ({
  onClick,
  next,
  prev,
}: {
  onClick: Function;
  next: boolean;
  prev: boolean;
}) => {
  const { classes } = useIconButtonBlueStyles();
  return (
    <IconButton
      classes={classes}
      color="secondary"
      onClick={onClick as React.MouseEventHandler<HTMLElement>}
      aria-label={next ? "Next" : prev ? "Previous" : undefined}
      size="large"
    >
      {next ? <ChevronRightIcon /> : prev ? <ChevronLeftIcon /> : undefined}
    </IconButton>
  );
};

const ActionButtons = ({
  handleAdd,
  handleRotate,
  handleDelete,
  showDownloadButton,
  currentImage,
  disabled,
  downloadTitle = "Download",
  addTitle = "Add",
  deleteTitle = "Delete",
  rotateTitle = "Rotate",
}: {
  handleAdd?: React.MouseEventHandler;
  handleRotate?: React.MouseEventHandler;
  handleDelete?: React.MouseEventHandler;
  showDownloadButton?: boolean;
  currentImage: { url: string; title: string } | undefined;
  disabled?: boolean;
  downloadTitle?: string;
  addTitle?: string;
  deleteTitle?: string;
  rotateTitle?: string;
}) => {
  const theme = useTheme();

  const { classes: classesLight } = useIconButtonLightStyles();
  const { classes: classesDark } = useIconButtonDarkStyles();

  const { downloadFileFromUrl, downloading } = useDownloadFile();

  const classes = theme.palette.mode === "dark" ? classesDark : classesLight;

  const { isMaxPhone } = useScreenWidth();

  const numberOfButtons = [
    handleAdd,
    handleDelete,
    handleRotate,
    showDownloadButton,
  ].filter(item => !!item).length;

  if (numberOfButtons === 0 || !currentImage) return null;

  if (isMaxPhone && numberOfButtons > 1) {
    return (
      <MenuButton
        buttonProps={{
          classes,
          size: "large",
          style: { flexShrink: 0, flexGrow: 0, width: 48, height: 48 },
        }}
      >
        {handleRotate && (
          <MenuItemWithIcon
            disabled={disabled}
            icon={<RotateRightIcon />}
            onClick={handleRotate}
          >
            {rotateTitle}
          </MenuItemWithIcon>
        )}
        {showDownloadButton && (
          <MenuItemWithIcon
            component={Link}
            icon={
              !downloading ? (
                <DownloadIcon />
              ) : (
                <CircularProgress color="inherit" size="1rem" />
              )
            }
            download={currentImage.title}
            target="_blank"
            rel="noopener noreferrer"
            href={currentImage.url}
            onClick={async (e: React.MouseEvent) => {
              e.stopPropagation();
              if (e.ctrlKey || e.metaKey) return;
              e.preventDefault();
              if (currentImage)
                await downloadFileFromUrl(currentImage.url, currentImage.title);
            }}
          >
            {downloadTitle}
          </MenuItemWithIcon>
        )}
        {handleDelete && (
          <MenuItemWithIcon
            disabled={disabled}
            icon={<DeleteIcon />}
            onClick={handleDelete}
          >
            {deleteTitle}
          </MenuItemWithIcon>
        )}
        {handleAdd && (
          <MenuItemWithIcon
            disabled={disabled}
            icon={<AddAPhotoIcon />}
            onClick={handleAdd}
          >
            {addTitle}
          </MenuItemWithIcon>
        )}
      </MenuButton>
    );
  }

  return (
    <>
      {handleRotate && (
        <IconButton
          classes={classes}
          color="primary"
          disabled={disabled}
          onClick={handleRotate}
          size="large"
        >
          <RotateRightIcon />
        </IconButton>
      )}
      {showDownloadButton && (
        <IconButton
          classes={classes}
          color="primary"
          component={Link}
          download={currentImage.title}
          target="_blank"
          rel="noopener noreferrer"
          href={currentImage.url}
          onClick={async (e: React.MouseEvent) => {
            e.stopPropagation();
            if (e.ctrlKey || e.metaKey) return;
            e.preventDefault();
            if (currentImage)
              await downloadFileFromUrl(currentImage.url, currentImage.title);
          }}
          size="large"
        >
          {!downloading ? (
            <DownloadIcon />
          ) : (
            <CircularProgress color="inherit" size="1.5rem" />
          )}
        </IconButton>
      )}
      {handleDelete && (
        <IconButton
          classes={classes}
          color="primary"
          disabled={disabled}
          onClick={handleDelete}
          size="large"
        >
          <DeleteIcon />
        </IconButton>
      )}

      {handleAdd && (
        <IconButton
          classes={classes}
          color="primary"
          disabled={disabled}
          onClick={handleAdd}
          size="large"
        >
          <AddAPhotoIcon />
        </IconButton>
      )}
    </>
  );
};

// TODO
// const StyledBulletList = styled("ul")`
//   display: flex;
//   justify-content: center;
//   list-style-type: none;
//   padding: 0;
//   margin: 15px 0;
// `;

const StyledWrapper = styled(
  "div",
  transientOptions
)<{
  $width: React.CSSProperties["width"];
  $height?: React.CSSProperties["height"];
  $maxHeight?: React.CSSProperties["maxHeight"];
  $aspectRatio?: React.CSSProperties["aspectRatio"];
}>(({ $width = "100%", $height = "100%", $maxHeight, $aspectRatio }) => ({
  position: "relative",
  overflow: "hidden",
  width: $width,
  height: $height,
  maxHeight: $maxHeight,
  aspectRatio: $aspectRatio,
}));

const StyledImageWrapper = styled(
  "div",
  transientOptions
)<{ $clickable: boolean }>(({ $clickable }) => ({
  display: "flex",
  position: "relative",
  height: "100%",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  flexGrow: 1,
  flexShrink: 1,
  userSelect: "none",
  ...($clickable ? { cursor: "pointer" } : undefined),
}));

const StyledPicture = styled("img")({
  display: "flex",
  objectFit: "contain",
  maxWidth: "100%",
  maxHeight: "100%",
  userSelect: "none",
  touchAction: "none",
  pointerEvents: "none",
});
