import {
  CssBaseline,
  LocaleProvider,
  Theme,
  ThemeProvider as UIThemeProvider,
} from "@msys/ui";
import { useTolgee } from "@tolgee/react";
export { inverseTheme, theme } from "@msys/ui";

export const MuiThemeProvider = ({
  theme,
  children,
}: React.PropsWithChildren<{ theme?: Theme }>) => {
  const language = useTolgee(["language"]).getLanguage()!;

  return (
    <UIThemeProvider locale={language} theme={theme}>
      <CssBaseline />
      <LocaleProvider value={language}>{children}</LocaleProvider>
    </UIThemeProvider>
  );
};

/** @deprecated */
export enum size {
  navBarExpandedWidth = 310,
  navBarWidth = 64,
  navBarItemHeight = 52,
  topMenuLogoHeight = 24,
  topMenuSize = 60,
  topMenuIconSize = 40,
  topMenuHorizontalMargin = 8,
  topLogoMaxWidth = 204,
  bottomBarSize = 60,
  treeSpacing = 2, // tree item spacing
  treeIndicatorWidth = 18, // tree indicator width
  treeItemHeight = 52, // tree item element height, excluding bottom margin
  treeItemFullHeight = 54, // tree item element height + bottom margin (2px)
  tableItemFullHeight = 32, // table item element height
}

/** @deprecated */
export enum px {
  none = "0",
  xxs = "2px",
  xs = "4px",
  s = "8px",
  m = "12px",
  l = "16px",
  xl = "20px",
  xxl = "36px",
  xxxl = "48px",
}

/** @deprecated */
export enum media {
  maxPhone = "max-width: 983px",
  minTablet = "min-width: 984px",
  maxTablet = "max-width: 1199px",
  minDesktop = "min-width: 1200px",
}

/** @deprecated */
export enum color {
  blue0 = "#001e64",
  blue1 = "#033099",
  blue2 = "#285fa5",
  blue3 = "#5087c3",
  blue4 = "#7dafd7",
  blue5 = "#afcde6",
  blue6 = "#c8dceb",
  blue7 = "#d7e6f0",
  blue8 = "#e8f0f5",
  blue9 = "#f2f6fa",
  blue10 = "#f8f9fc",
  default = "#377dc3",
  danger = "#ff735a",
  dangerLight = "#ff8e7a",
  dangerDark = "#ff593b",
  green = "#4caf50",
  greenAlpha = "rgba(76,175,80, 0.2)",
  greenLight = "#e6fffa",
  greenDark = "#37c8aa",
  red = "#ff6e78",
  redAlpha = "rgba(255,110,120, 0.2)",
  redLighter = "#fbedeb",
  redLight = "#ffe1e6",
  redDark = "#fa4664",
  orange = "#ff9800",
  orangeLight = "#ffaa2c",
  orangeDark = "#da8200",
  yellow = "#ffe178",
  yellowLight = "#fffabe",
  yellowDark = "#fad750",
  background = "#fcfdff",
  white = "#ffffff",
  black = "#000000",
  primary = "#001e64",
  secondary = "#5087c3",
  turquoise = "#18A999",
  lightBlue = "#1EA7FD",
  grey = "rgba(0, 0, 0, 0.38)",
  greyDark = "rgba(0, 0, 0, 0.58)",
  purple = "#cb6bb2",
  msOrange = "#ff735a",
  msOrangeLight = "#ff8e7a",
  msOrangeDark = "#ff593b",
}

/** @deprecated */
export enum cssValue {
  boxShadow = "0 1px 5px 0 rgba(0, 30, 100, 0.15)",
  treeBoxShadow = "0px 0px 4px rgba(0, 30, 100, 0.24)",
  flyoutShadow = "0 8px 12px -2px rgba(0, 30, 100, 0.2)",
  buttonShadow = "0 2px 6px 0 rgba(0, 0, 0, 0.1)",
  selectedTreeShadow = "0px 0px 4px #377dc3",
  selectedOutlineShadow = "0 0 0 2px #377dc3",
  selectedBorderShadow = "inset 0 0 0 2px #377dc3",
  selectedTableBorderShadow = "inset 0 0 0 1px #377dc3",
  selectedDescendantTreeShadow = "0px 0px 4px #afcde6",
  selectedDescendantBorderShadow = "inset 0 0 0 2px #afcde6",
  preselectedBorderShadow = "inset 0 0 0 2px #18A999",
  preselectedOutlineShadow = "0 0 0 2px #18A999",
  preselectedTableBorderShadow = "inset 0 0 0 1px #18A999",
  // paperTreeShadow = "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  boxBorderRadius = "4px",
  roundBorderRadius = "40px",
  openTransition = ".45s cubic-bezier(.05,.94,.19,.95)",
  labelTransform = "translate(0, -8px) scale(0.6875)",
}
