// Do not allow passing both width and height as this could result in distorted image
export interface WithWidth {
  width: number;
  scaling?: number;
}
export interface WithHeight {
  height: number;
  scaling?: number;
}

type Args = { url: string } & ({} | WithWidth | WithHeight);

// Modifies any image url from UploadCare with adding resize parameters
// E.g. https://ucarecdn.com/85b5644f-e692-4855-9db0-8c5a83096e25/ ->
// https://ucarecdn.com/85b5644f-e692-4855-9db0-8c5a83096e25/-/preview/-/resize/550x/image@1x.jpg
export const getImageUrl = ({ url, ...size }: Args) => {
  // skip if not uploadcare url
  if (!url.match(/^https?:\/\/ucarecdn\.com\//)) return url;

  const [, rotate = "-/autorotate/yes/"] = // do we need autorotate by default?
    url.match(/(-\/rotate\/[^/]+\/)/) ?? []; // eg. "/rotate/90/"

  let resize = "";
  let scale = "";
  if ("width" in size && size.width) {
    const { width, scaling = 1 } = size;
    const w = width * scaling;
    resize = `-/resize/${w}x`;
    if (scaling !== 1) {
      scale = `@${scaling}x`;
    }
  } else if ("height" in size && size.height) {
    const { height, scaling = 1 } = size;
    const h = height * scaling;
    resize = `-/resize/x${h}`;
    if (scaling !== 1) {
      scale = `@${scaling}x`;
    }
  }

  return url.replace(
    /^(https?:\/\/ucarecdn\.com\/[^/]+)(.*)$/,
    `$1/-/preview/${rotate}${resize}/image${scale}.jpg`
  );
};
