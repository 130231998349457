import { makeStyles } from "../styles";

// source: https://stackoverflow.com/questions/56797382/how-to-style-button-from-mui-icon-button

export const useIconButtonLightStyles = makeStyles()(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    "&:hover:not(.Mui-disabled), &.Mui-focused, &.Mui-focusVisible": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  disabled: {
    color: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[200],
  },
}));

export const useIconButtonDarkStyles = makeStyles()(theme => ({
  root: {
    backgroundColor: "rgba(0,0,0, 0.5)",
    color: "rgba(255,255,255, 0.65)",
    "&:hover:not(.Mui-disabled), &.Mui-focused, &.Mui-focusVisible": {
      backgroundColor: "rgba(0,0,0, 0.75)",
      color: "rgba(255,255,255, 1)",
    },
  },
  disabled: {
    color: theme.palette.grey[600],
    backgroundColor: theme.palette.grey[400],
  },
}));

export const useIconButtonBlueStyles = makeStyles()(theme => ({
  root:
    theme.palette.mode === "dark"
      ? {
          backgroundColor: "rgba(0,0,0, 0.5)",
          color: "rgba(255,255,255, 0.65)",
          "&:hover:not(.Mui-disabled), &.Mui-focused, &.Mui-focusVisible": {
            backgroundColor: "rgba(0,0,0, 0.75)",
            color: "rgba(255,255,255, 1)",
          },
        }
      : {
          backgroundColor: "rgba(215, 230, 240, 0.5)",
          "&:hover:not(.Mui-disabled), &.Mui-focused, &.Mui-focusVisible": {
            backgroundColor: "rgba(215, 230, 240, 0.75)",
          },
        },
  disabled: {
    color: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[200],
  },
}));
