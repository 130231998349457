// source: https://github.com/antonfisher/react-simple-timefield/blob/master/src/index.tsx

import padStart from "lodash.padstart";
import React, { ChangeEvent, ReactElement } from "react";

export const COLON = ":";
export const DURATION_INPUT_DEFAULT_VALUE = `00${COLON}00`;

const isNumber = (value: any): boolean => {
  const number = Number(value);
  return !isNaN(number) && String(value) === String(number);
};

const formatTimeItem = (value?: string | number): string =>
  padStart((+(value || "")).toString(), 2, "0");

function validateTimeAndCursor(
  value = "",
  defaultValue = "",
  colon = COLON,
  cursorPosition = 0
): [string, number] {
  const [, oldM] = defaultValue.split(colon);

  let newCursorPosition = Number(cursorPosition);
  let [newH, newM] = String(value).split(colon);

  newH = formatTimeItem(newH);

  newM = formatTimeItem(newM);
  if (Number(newM[0]) > 5) {
    newM = oldM;
    newCursorPosition -= 1;
  }

  const validatedValue = `${newH}${colon}${newM}`;

  return [validatedValue, newCursorPosition];
}

type onChangeType = (
  event: ChangeEvent<HTMLInputElement>,
  value: string
) => void;

interface Props {
  value?: string;
  onChange?: onChangeType;
  input: ReactElement | null;
  inputRef?: () => HTMLInputElement | null;
  colon?: string;
  enabled?: boolean;
}

interface State {
  value: string;
  _colon: string;
  _defaultValue: string;
}

export class DurationInput extends React.Component<Props, State> {
  static defaultProps: Props = {
    enabled: true,
    input: null,
    colon: COLON,
  };

  constructor(props: Props) {
    super(props);

    const _defaultValue = DURATION_INPUT_DEFAULT_VALUE;
    const _colon = COLON;

    if (!props.enabled) {
      this.state = {
        value: props.value || "",
        _colon,
        _defaultValue,
      };
    } else {
      const [validatedTime] = validateTimeAndCursor(
        props.value,
        _defaultValue,
        _colon
      );

      this.state = {
        value: validatedTime,
        _colon,
        _defaultValue,
      };
    }

    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidUpdate(prevProps: Props): void {
    if (this.props.value !== prevProps.value) {
      if (!this.props.enabled) {
        this.setState({
          value: this.props.value || "",
        });
      } else {
        const [validatedTime] = validateTimeAndCursor(
          this.props.value,
          this.state._defaultValue,
          this.state._colon
        );
        this.setState({
          value: validatedTime,
        });
      }
    }
  }

  onInputChange(
    event: ChangeEvent<HTMLInputElement>,
    callback: onChangeType
  ): void {
    const oldValue = this.state.value;
    const inputEl = event.target;
    const inputValue = inputEl.value;
    const position = inputEl.selectionEnd || 0;
    const isTyped = inputValue.length > oldValue.length;
    const cursorCharacter = inputValue[position - 1];
    const addedCharacter = isTyped ? cursorCharacter : null;
    const removedCharacter = isTyped ? null : oldValue[position];
    const replacedSingleCharacter =
      inputValue.length === oldValue.length ? oldValue[position - 1] : null;
    const colon = this.state._colon;

    let newValue = oldValue;
    let newPosition = position;

    const colonPosition = oldValue.indexOf(colon);

    if (addedCharacter !== null) {
      if (position > oldValue.length) {
        newPosition = oldValue.length;
      } else if (position - 1 === colonPosition && addedCharacter === colon) {
        /* prettier-ignore */
        newValue = `${inputValue.substr(0, position - 1)}${colon}${inputValue.substr(position + 1)}`;
      } else if (position - 1 === colonPosition && isNumber(addedCharacter)) {
        if (oldValue[0] === "0") {
          /* prettier-ignore */
          newValue = `${inputValue.substr(0, position - 1)}${colon}${addedCharacter}${inputValue.substr(position + 2)}`;
          newPosition = position + 1;
        } else {
          /* prettier-ignore */
          newValue = `${inputValue.substr(0, position - 1)}${addedCharacter}${colon}${inputValue.substr(position + 1)}`;
        }
      } else if (isNumber(addedCharacter)) {
        // user typed a number
        /* prettier-ignore */
        if (
          position - 1 < colonPosition &&
          (oldValue.length > DURATION_INPUT_DEFAULT_VALUE.length ||
            !(
              (position - 1 === 0 && oldValue[0] === "0" && oldValue[1] === "0") ||
              (position - 1 === 1 && oldValue[1] === "0")
            ))
        ) {
          newValue = inputValue;
        } else {
          newValue =
            inputValue.substr(0, position - 1) +
            addedCharacter +
            inputValue.substr(position + 1);
        }
      } else {
        // if user typed NOT a number, then keep old value & position
        newPosition = position - 1;
      }
    } else if (replacedSingleCharacter !== null) {
      // user replaced only a single character
      if (isNumber(cursorCharacter)) {
        if (position - 1 === colonPosition) {
          /* prettier-ignore */
          newValue = `${inputValue.substr(0, position - 1)}${colon}${inputValue.substr(position)}`;
        } else {
          newValue = inputValue;
        }
      } else {
        // user replaced a number on some non-number character
        newValue = oldValue;
        newPosition = position - 1;
      }
    } else if (
      typeof cursorCharacter !== "undefined" &&
      cursorCharacter !== colon &&
      !isNumber(cursorCharacter)
    ) {
      // set of characters replaced by non-number
      newValue = oldValue;
      newPosition = position - 1;
    } else if (removedCharacter !== null) {
      if (position === colonPosition && removedCharacter === colon) {
        if (oldValue.length > DURATION_INPUT_DEFAULT_VALUE.length) {
          /* prettier-ignore */
          newValue = `${inputValue.substr(0, position - 1)}${colon}${inputValue.substr(position)}`;
        } else {
          /* prettier-ignore */
          newValue = `${inputValue.substr(0, position - 1)}0${colon}${inputValue.substr(position)}`;
        }
        newPosition = position - 1;
      } else {
        // user removed a number
        /* prettier-ignore */
        if (position < colonPosition && oldValue.length > DURATION_INPUT_DEFAULT_VALUE.length) {
          newValue = inputValue;
        } else {
          /* prettier-ignore */
          newValue = `${inputValue.substr(0, position)}0${inputValue.substr(position)}`;
        }
      }
    }

    const [validatedTime, validatedCursorPosition] = validateTimeAndCursor(
      newValue,
      oldValue,
      colon,
      newPosition
    );

    this.setState({ value: validatedTime }, () => {
      inputEl.selectionStart = validatedCursorPosition;
      inputEl.selectionEnd = validatedCursorPosition;
      callback(event, validatedTime);
    });

    event.persist();
  }

  render() {
    const { value } = this.state;
    const { onChange, input, inputRef, colon, enabled, ...props } = this.props;
    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
      this.onInputChange(event, (e: ChangeEvent<HTMLInputElement>, v: string) =>
        onChange?.(e, v)
      );

    if (input) {
      return React.cloneElement(input, {
        ...props,
        value,
        onChange: onChangeHandler,
      });
    }

    return (
      <input
        type="text"
        {...props}
        ref={inputRef}
        value={value}
        onChange={onChangeHandler}
      />
    );
  }
}
