import { css } from "@emotion/react";
import { StyledComponent } from "@emotion/styled";
import { Box, BoxProps, Theme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MUIStyledCommonProps } from "@mui/system";
import React from "react";
import { transientOptions } from "../styles";

export const ListItemWrapper: StyledComponent<
  MUIStyledCommonProps<Theme> & {
    $hasAction?: boolean;
    children?: React.ReactNode;
  }
> = styled("div", transientOptions)<{ $hasAction?: boolean }>`
  position: relative;

  .msys-list-item-box {
    border-bottom: none !important;
  }

  ${({ $hasAction }) =>
    $hasAction
      ? css`
          .msys-list-item-box {
            padding-right: 48px;
          }
        `
      : ""}

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
  }
`;

export const ListItemAction: StyledComponent<
  MUIStyledCommonProps<Theme> & { children?: React.ReactNode }
> = styled("div")`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
`;

export const ListItem: StyledComponent<
  MUIStyledCommonProps<Theme> & {
    $selected?: boolean;
  } & BoxProps,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  {}
> = styled(Box, transientOptions)<{
  $selected?: boolean;
}>`
  display: block;
  color: inherit;
  text-decoration: none;
  ${({ onClick, $selected = false }) =>
    onClick && !$selected
      ? `cursor: pointer;`
      : $selected
      ? `cursor: default;`
      : ""}
  ${({ $selected = false, theme }) =>
    !$selected
      ? `
        :hover {
          background-color: ${theme.palette.grey[100]};
        }
      `
      : `
        box-shadow: inset 0 0 0 2px #377dc3;
      `}

  &:not(:last-child) {
    border-bottom: 1px solid
      ${({ $selected = false, theme }) =>
        $selected ? "transparent" : theme.palette.grey[300]};
  }
`;

ListItem.defaultProps = {
  className: "msys-list-item-box",
  p: 1,
};
