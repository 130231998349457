import React from "react";
import Draft from "draft-js";
import { Typography } from "@mui/material";
import Immutable from "immutable";

export const blockRenderMap = Draft.DefaultDraftBlockRenderMap.merge(
  Immutable.Map({
    "header-one": {
      wrapper: <Typography variant="h2" />,
    },
    "header-two": {
      wrapper: <Typography variant="h3" />,
    },
    "header-three": {
      wrapper: <Typography variant="h4" />,
    },
  })
);
