export type Attachment = {
  id: string;
  url: string;
  title: string;
  mimeType: string;
};

export type AttachmentImage = Attachment & { __type: "image" };
export type AttachmentPdf = Attachment & { __type: "pdf" };
export type AttachmentFile = Attachment & { __type: "file" };
export type Attachment3dModel = Attachment & { __type: "3d_model" };

export type TypedAttachment =
  | AttachmentImage
  | AttachmentPdf
  | AttachmentFile
  | Attachment3dModel;

export function processAttachment<A extends Attachment>(
  attachment: A
): A & TypedAttachment {
  if ([".gltf", ".glb"].some(ext => attachment.title.endsWith(ext))) {
    return { __type: "3d_model", ...attachment };
  }
  if (attachment.mimeType === "application/pdf") {
    return { __type: "pdf", ...attachment };
  }
  if (attachment.mimeType.toLowerCase().startsWith("image")) {
    return { __type: "image", ...attachment };
  }
  return { __type: "file", ...attachment };
}

export const isImage = <A extends TypedAttachment>(
  attachment: A
): attachment is Extract<A, { __type: "image" }> =>
  attachment.__type === "image";

export const isPdf = <A extends TypedAttachment>(
  attachment: A
): attachment is Extract<A, { __type: "pdf" }> => attachment.__type === "pdf";

export const isFile = <A extends TypedAttachment>(
  attachment: A
): attachment is Extract<A, { __type: "file" }> => attachment.__type === "file";

export const is3dModel = <A extends TypedAttachment>(
  attachment: A
): attachment is Extract<A, { __type: "3d_model" }> =>
  attachment.__type === "3d_model";

export const isImageOr3dModel = <A extends TypedAttachment>(
  attachment: A
): attachment is Extract<A, { __type: "image" } | { __type: "3d_model" }> =>
  attachment.__type === "image" || attachment.__type === "3d_model";

export const isPdfOrFile = <A extends TypedAttachment>(
  attachment: A
): attachment is Extract<A, { __type: "pdf" } | { __type: "file" }> =>
  attachment.__type === "pdf" || attachment.__type === "file";
