import { stripHtmlTags } from "@msys/common";
import { getDurationInSeconds } from "@msys/ui";
import { padStart } from "lodash";
import React from "react";
import { Location, matchPath } from "react-router-dom";
import reactStringReplace from "react-string-replace";

// entriesAmount("1.2.3.4.", ".") -> 4
export const entriesAmount = (string: string, entry: string): number => {
  let count = 0;
  for (let i = 0; i < string.length; count += +(entry === string[i++]));
  return count;
};

export const durationInMinutesToTime = (durationInMinutes: number): string => {
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = Math.floor(durationInMinutes % 60);
  return `${padStart(hours.toString(), 2, "0")}:${padStart(minutes.toString(), 2, "0")}`; /* prettier-ignore */
};

export const timeToDurationInMinutes = (time: string): number => {
  return time ? +time.split(":")[0] * 60 + +time.split(":")[1] : 0;
};

export const cleanHTML = (html: string) => {
  const text = stripHtmlTags(html, false).trim();
  if (text === "") return "";
  return html;
};

export const DURATION_MAX_VALUES = {
  none: 10 * 365 * 24,
  hours: 10 * 365 * 24,
  days: 10 * 365,
  weeks: 10 * 52,
  months: 10 * 12,
};

export const DEFAULT_QUOTE_VALIDITY_DURATION = getDurationInSeconds({
  durationType: "weeks",
  durationAmount: 2,
});

export const DEFAULT_PROJECT_DURATION = getDurationInSeconds({
  durationType: "weeks",
  durationAmount: 2,
});

export const DEFAULT_TICKET_DURATION = getDurationInSeconds({
  durationType: "hours",
  durationAmount: 2,
});

export const DEFAULT_INVOICE_PAYMENT_TERM_DURATION = getDurationInSeconds({
  durationType: "weeks",
  durationAmount: 2,
});

export function assertNever(x: never): never {
  throw new Error("Unexpected object: " + x);
}

export const compareNumbersFn = (
  a: number | undefined | null,
  b: number | undefined | null
) => {
  const aDefined = a !== undefined && a !== null;
  const bDefined = b !== undefined && b !== null;
  if (!aDefined && !bDefined) return 0;
  if (!aDefined) return 1;
  if (!bDefined) return -1;
  return a! - b!;
};

export const removeURLParameter = (
  search: string,
  paramName: string
): string => {
  const params = new URLSearchParams(search);
  params.delete(paramName);
  return params.toString();
};

export function matchPathPredicate(path: string, location: Location) {
  return (
    matchPath(path, location.pathname) !== null ||
    matchPath(path, location.pathname + location.hash) !== null
  );
}

export const trimFileExtension = (fileName: string) =>
  fileName.split(".").slice(0, -1).join(".");

export const getFileExtension = (fileName: string) =>
  fileName.split(".").slice(-1);

export const getSelectedText = () => {
  let selectedText: string | undefined;
  if (window.getSelection) {
    selectedText = window.getSelection()?.toString();
  } else if (document.getSelection) {
    selectedText = document.getSelection()?.toString();
    // @ts-ignore
  } else if (document?.selection && document?.selection?.type === "Text") {
    // @ts-ignore
    selectedText = document?.selection?.createRange()?.text;
  }
  return selectedText;
};

export function fromVersionNumberParamToVersionNumberInt(
  versionNumberFromParam: string | undefined
) {
  if (
    versionNumberFromParam === null ||
    versionNumberFromParam === undefined ||
    versionNumberFromParam === "latest"
  )
    return null;

  return parseInt(versionNumberFromParam, 10);
}

export function buildDocPath(pathToDoc: string, versionNumber: number | null) {
  return `${pathToDoc}/${versionNumber ?? "latest"}`;
}

export const isInTestMode = (): boolean => {
  try {
    return Boolean(sessionStorage.getItem("__MS_TEST_MODE__"));
  } catch (e) {
    // do nothing
    if (e instanceof Error) console.error(e);
  }
  return false;
};

export const highlightWithBold = (
  text: string,
  searchTerm: string
): React.ReactNode[] => {
  return reactStringReplace(text, searchTerm, match => <b>{match}</b>);
};

export function createRecursiveTryFocusElementFunction(
  selector: string,
  maxTryCount: number = 10
) {
  const tryFocusElement = (depth = 0) => {
    if (depth >= maxTryCount) {
      return;
    }

    const timeout = Math.pow(depth, 3) * 100;

    setTimeout(() => {
      const input: HTMLInputElement | null = document.querySelector(selector);

      if (!input) {
        tryFocusElement(depth + 1);
      } else if (input !== document.activeElement) {
        input.focus();
      }
    }, timeout);
  };

  return tryFocusElement;
}
