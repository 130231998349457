import { isArray, mergeWith } from "lodash";

function customizer(existing: any, incoming: any) {
  if (isArray(existing)) {
    return incoming;
  }
}

export const mergeObjects = (existing: any, incoming: any) => {
  return mergeWith({}, existing, incoming, customizer);
};
