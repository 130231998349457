import TagManager from "react-gtm-module";
import { DEPLOY_ENV } from "../environment";

const MEASUREMENT_ID = {
  // TODO: use environment variable
  dev: "G-4BH4SYZ8HK",
  stg: "G-W211Z025SH",
  partner: "G-LC92YMME50",
  prod: "G-BYYV0RQ089",
};

if (DEPLOY_ENV !== "local") {
  const tagManagerArgs = {
    gtmId: MEASUREMENT_ID[DEPLOY_ENV],
    dataLayer: {
      js: new Date(),
    },
  };
  TagManager.initialize(tagManagerArgs);
}
