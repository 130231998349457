import { noop } from "lodash";
import React from "react";
import { Modal } from "./Modal";
import { SnackbarMessage, useSnackbar } from "notistack";

interface Props<P extends { handleClose: () => void }> {
  children: React.ReactElement;
  Modal: React.ComponentType<P>;
  modalProps?: Omit<P, "handleClose">;
  onOpenCallback?: (e: React.MouseEvent<HTMLElement>) => void;
  onCloseCallback?: () => void;
  disabled?: boolean;
}

const defaultModalProps = {};

function _ModalOpenButton<P extends { handleClose: () => void }>(
  {
    children,
    Modal,
    modalProps = defaultModalProps as P,
    onOpenCallback,
    onCloseCallback,
    disabled,
    ...props
  }: React.PropsWithChildren<Props<P>>,
  ref: React.ForwardedRef<HTMLElement>
) {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = React.useState(false);

  const buttonWithOpen = React.cloneElement(children, {
    onClick:
      disabled ?? children.props.disabled
        ? noop
        : async (e: React.MouseEvent<HTMLElement>) => {
            await children.props.onClick?.(e);
            setIsOpen(true);
            onOpenCallback?.(e);
          },
    disabled: disabled ?? children.props.disabled,
    ...props,
    ref,
  });

  const buttonWithLoadingIndicator = React.cloneElement(children, {
    ...props,
    isLoading: true,
  });

  return (
    <ErrorBoundary
      handleClose={() => {
        setIsOpen(false);
        onCloseCallback?.();
      }}
      showError={message => enqueueSnackbar(message, { variant: "error" })}
    >
      <React.Suspense fallback={buttonWithLoadingIndicator}>
        {buttonWithOpen}
        {isOpen && (
          // @ts-ignore
          <Modal
            {...modalProps}
            handleClose={() => {
              setIsOpen(false);
              onCloseCallback?.();
            }}
          />
        )}
      </React.Suspense>
    </ErrorBoundary>
  );
}

export const ModalOpenButton = React.memo(React.forwardRef(_ModalOpenButton));

interface ErrorBoundaryProps {
  handleClose: () => void;
  showError: (message: SnackbarMessage) => void;
}

class ErrorBoundary extends React.Component<
  React.PropsWithChildren<ErrorBoundaryProps>,
  { hasError: boolean }
> {
  constructor(props: React.PropsWithChildren<ErrorBoundaryProps>) {
    super(props);
    // this.state = { hasError: false };
  }

  // static getDerivedStateFromError(error: unknown) {
  //   // Update state so the next render will show the fallback UI.
  //   return { hasError: true };
  // }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    // You can also log the error to an error reporting service
    if (error instanceof Error) {
      this.props.showError(error.message);
    } else if (
      error instanceof Object &&
      "__typename" in error &&
      typeof error.__typename === "string"
    ) {
      this.props.showError(error.__typename);
    } else {
      this.props.showError("Error occurred");
    }

    this.props.handleClose();
  }

  render() {
    // if (this.state.hasError) {
    //   // You can render any custom fallback UI
    //   return (
    //     <Modal
    //       actionButtons={[
    //         {
    //           label: "Close",
    //           handleClick: () => {
    //             this.setState({ hasError: false });
    //             this.props.handleClose();
    //           },
    //         },
    //       ]}
    //     >
    //       Something went wrong
    //     </Modal>
    //   );
    // }

    return this.props.children;
  }
}
