import React from "react";
import { useUpdateEffect } from "react-use";

const deserializer = JSON.parse;
const serializer = JSON.stringify;
const processOnGet = <T,>(value: T) => value;
const processOnSet = <T,>(value: T) => value;

interface Options<T extends Exclude<any, undefined>> {
  deserializer?(string: string): T;
  serializer?(value: T): string;
  storeDefaultValue?: boolean;
  processOnGet?: (value: T) => T;
  processOnSet?: (value: T) => T;
}

// here updating the value won't trigger re-rendering; needed to store expanded state of a tree
export const useLocalStorageAsRef = <T extends Exclude<any, undefined>>(
  key: string,
  defaultValue: T,
  options?: Options<T>
): [React.MutableRefObject<T>, (newValue: T | undefined) => void] => {
  const state = React.useRef<T>(
    getFromLocalStorage(key, defaultValue, options)
  );

  const defaultValueRef = React.useRef(defaultValue);
  defaultValueRef.current = defaultValue;

  const optionsRef = React.useRef(options);
  optionsRef.current = options;

  useUpdateEffect(() => {
    state.current = getFromLocalStorage(
      key,
      defaultValueRef.current,
      optionsRef.current
    );
  }, [key]);

  const set: (newValue: T | undefined) => void = React.useCallback(
    newValue => {
      setToLocalStorage(key, newValue, optionsRef.current);
      state.current =
        newValue !== undefined ? newValue : defaultValueRef.current;
    },
    [key]
  );

  return [state, set];
};

// here we're updating the value and trigger re-rendering
export const useLocalStorageAsState = <T extends Exclude<any, undefined>>(
  key: string,
  defaultValue: T,
  subscribeToStorageEvents: boolean = false,
  options?: Options<T>
): [T, (newValue: T | undefined) => void] => {
  const [state, setState] = React.useState<T>(
    getFromLocalStorage(key, defaultValue, options)
  );

  const defaultValueRef = React.useRef(defaultValue);
  defaultValueRef.current = defaultValue;

  const optionsRef = React.useRef(options);
  optionsRef.current = options;

  useUpdateEffect(() => {
    setState(
      getFromLocalStorage(key, defaultValueRef.current, optionsRef.current)
    );
  }, [key]);

  const set: (newValue: T | undefined) => void = React.useCallback(
    newValue => {
      setToLocalStorage(key, newValue, optionsRef.current);
      setState(newValue !== undefined ? newValue : defaultValueRef.current);
    },
    [key]
  );

  // subscribe to the storage event
  React.useEffect(() => {
    if (!subscribeToStorageEvents) return;

    const handler = (e: StorageEvent) => {
      if (e.storageArea === localStorage && e.key === key) {
        try {
          if (e.newValue) {
            setState(deserializer(e.newValue));
          } else {
            setState(defaultValueRef.current);
          }
        } catch (e) {
          setState(defaultValueRef.current);
          if (e instanceof Error) console.error(e);
        }
      }
    };

    window.addEventListener("storage", handler);
    return () => {
      window.removeEventListener("storage", handler);
    };
  }, [subscribeToStorageEvents, key]);

  return [state, set];
};

export const getFromLocalStorage = <T extends Exclude<any, undefined>>(
  key: string,
  defaultValue: T,
  options?: Options<T>
): T => {
  const { storeDefaultValue = false } = options ?? {};
  try {
    const localStorageValue = localStorage.getItem(key);
    if (localStorageValue !== null) {
      const value = (options?.processOnGet ?? processOnGet)(
        (options?.deserializer ?? deserializer)(localStorageValue)
      );
      localStorage.setItem(
        key,
        (options?.serializer ?? serializer)(
          (options?.processOnSet ?? processOnSet)(value)
        )
      );
      return value;
    }
    if (storeDefaultValue) {
      localStorage.setItem(
        key,
        (options?.serializer ?? serializer)(
          (options?.processOnSet ?? processOnSet)(defaultValue)
        )
      );
    }
  } catch (e) {
    // do nothing
    if (e instanceof Error) console.error(e);
  }
  return defaultValue;
};

export const setToLocalStorage = <T extends Exclude<any, undefined>>(
  key: string,
  value: T | undefined,
  options?: Options<T>
) => {
  try {
    if (value === undefined) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(
        key,
        (options?.serializer ?? serializer)(
          (options?.processOnSet ?? processOnSet)(value)
        )
      );
    }
  } catch (e) {
    // do nothing
    if (e instanceof Error) console.error(e);
  }
};
