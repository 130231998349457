import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box, BoxProps, IconProps } from "@mui/material";

type Props = {
  isVisible: boolean;
  color?: IconProps["color"];
  fontSize?: IconProps["fontSize"];
  padding?: BoxProps["padding"];
};

export function VisibilityIndicatorIcon({
  isVisible,
  color = "disabled",
  fontSize,
  padding,
}: Props) {
  return (
    <Box
      padding={
        padding ?? "5px" // TODO: can we get IconButton size=small padding somehow from theme?
      }
      display={"flex"}
    >
      {isVisible ? (
        <VisibilityIcon color={color} fontSize={fontSize} />
      ) : (
        <VisibilityOffIcon color={color} fontSize={fontSize} />
      )}
    </Box>
  );
}
