import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { IconButton, Menu, ThemeProvider, Tooltip } from "@mui/material";
import React from "react";
import { theme } from "../Theme";

interface Props {
  Icon?: React.ReactElement;
  Button?: React.ReactElement;
  buttonProps?: React.ComponentProps<typeof IconButton>;
  menuProps?: Omit<
    React.ComponentProps<typeof Menu>,
    "open" | "anchorEl" | "keepMounted" | "onClose" | "onClick"
  >;
  dataTestId?: string;
  tooltip?: string;
  closeOnClick?: boolean;
  onToggle?(isOpen: boolean): void;
  renderMenu?(menu: React.ReactElement): React.ReactElement;
  disabled?: boolean;
  hideWhenEmpty?: boolean;
}

// need to override topbar theme
export const renderMenuWithTheme = (
  menu: React.ReactElement
): React.ReactElement => <ThemeProvider theme={theme}>{menu}</ThemeProvider>;

export const defaultRenderMenu = (
  menu: React.ReactElement
): React.ReactElement => menu;

export const MenuButton = ({
  dataTestId,
  children,
  Icon,
  Button,
  buttonProps,
  menuProps,
  tooltip,
  closeOnClick = true,
  onToggle,
  renderMenu = defaultRenderMenu,
  disabled,
  hideWhenEmpty = true,
}: React.PropsWithChildren<Props>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    onToggle?.(true);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    onToggle?.(false);
  };

  const shouldRenderMenu = hideWhenEmpty
    ? Array.isArray(children)
      ? children.some(child => !!child)
      : !!children
    : true;

  if (!shouldRenderMenu) return null;

  const button = Button ? (
    React.cloneElement(Button, {
      onClick: openMenu,
      onMouseDown: (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
      },
      onTouchStart: (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
      },
      "aria-haspopup": "true",
      "data-testid": dataTestId,
    })
  ) : (
    <IconButton
      aria-haspopup="true"
      data-testid={dataTestId}
      style={{ flexShrink: 0, flexGrow: 0 }}
      color="primary"
      size="small"
      onClick={openMenu}
      onMouseDown={e => {
        e.stopPropagation();
      }}
      onTouchStart={e => {
        e.stopPropagation();
      }}
      disabled={disabled}
      {...buttonProps}
    >
      {Icon ?? <MoreHorizIcon />}
    </IconButton>
  );

  return (
    <>
      <Tooltip title={tooltip ?? ""}>{button}</Tooltip>
      {renderMenu(
        <Menu
          MenuListProps={{ disablePadding: true }}
          anchorEl={anchorEl}
          keepMounted // we need to keep it to `true` to allow open modals from menu items
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={e => {
            e.stopPropagation();
            if (anchorEl && closeOnClick) handleClose(e);
          }}
          sx={{ my: 0.5 }}
          {...menuProps}
        >
          {children}
        </Menu>
      )}
    </>
  );
};
