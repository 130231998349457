import React from "react";
import Keycloak from "keycloak-js";

export interface AuthContext {
  isReady: boolean;
  isAuthenticated: boolean;
  auth: Pick<
    Keycloak,
    | "token"
    | "updateToken"
    | "login"
    | "logout"
    | "register"
    | "idTokenParsed"
    | "createRegisterUrl"
  >;
}

export const AuthContext = React.createContext<AuthContext>({
  isReady: false,
  isAuthenticated: false,
  auth: {
    token: undefined,
    updateToken: asyncNoop,
    login: asyncNoop,
    logout: asyncNoop,
    register: asyncNoop,
    createRegisterUrl: dummyCreateRegisterUrl,
    idTokenParsed: undefined,
  },
});

async function asyncNoop(...args: any[]): Promise<any> {}
function dummyCreateRegisterUrl() {
  return "";
}
