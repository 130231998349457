import BigNumber from "bignumber.js";
import padStart from "lodash.padstart";
import moment from "moment";

export function getFormattedNumber(value: number, locale: string) {
  const formatter = new Intl.NumberFormat(locale);
  return formatter.format(value);
}

export function getFormattedInteger(value: number) {
  return String(Math.floor(value));
}

export function getEditableInteger(value: number) {
  if (value === 0) {
    return "";
  } else {
    return getFormattedInteger(value);
  }
}

export function getFormattedFloat(
  value: number,
  locale: string,
  options?: Intl.NumberFormatOptions
) {
  const formatter = new Intl.NumberFormat(
    locale,
    options ?? {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }
  );

  return formatter.format(value);
}

export function getEditableFloat(
  value: number,
  locale: string,
  returnEmptyStringIfZero: boolean = true
) {
  if (returnEmptyStringIfZero && value === 0) {
    return "";
  } else {
    const separator = new Intl.NumberFormat(locale)
      .format(1111)
      .replace(/1/g, "");
    const formatter = new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });

    return formatter
      .format(value)
      .replace(new RegExp(`\\${separator}`, "g"), "");
  }
}

export function getFloat(
  value: string,
  locale: string,
  returnZeroIfNaN: boolean = true
) {
  const numericValue = value.replace(/[^\d.,-]/g, "");
  const separator = new Intl.NumberFormat(locale)
    .format(1111)
    .replace(/1/g, "");
  const decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, "");
  const formatedValue = numericValue
    .replace(new RegExp(`\\${separator}`, "g"), "")
    .replace(new RegExp(`\\${decimal}`, "g"), ".");
  const numberValue = parseFloat(formatedValue);

  if (Number.isNaN(numberValue) && returnZeroIfNaN) return 0;

  return numberValue;
}

export function getFormattedPercentage(
  value: number | BigNumber,
  locale: string
) {
  if (BigNumber.isBigNumber(value)) {
    return getFormattedFloat(value.toNumber() * 100, locale) + "%";
  }

  return getFormattedFloat(value * 100, locale) + "%";
}

export enum Currency {
  Eur = "EUR",
}

export function getFormattedPrice(
  value: number | undefined,
  locale: string
): string | undefined {
  if (value === undefined) return;

  const currency = Currency.Eur;
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(value).replace(currency, getCurrencySymbol(currency));
}

function getCurrencySymbol(currency: Currency) {
  switch (currency) {
    case Currency.Eur:
      return "€";
  }
}

export function getFormattedDate(
  value: Date | moment.Moment | number | string,
  locale: string
) {
  return moment(value).locale(locale).format("L");
}

export function getFormattedDateWithoutYear(
  value: Date | moment.Moment | number | string,
  locale: string
) {
  return moment(value).locale(locale).toDate().toLocaleDateString(locale, {
    day: "2-digit",
    month: "2-digit",
  });
}

export function getFormattedDateTime(
  value: Date | moment.Moment | number | string,
  locale: string
) {
  return `${moment(value).locale(locale).format("L")} ${moment(value)
    .locale(locale)
    .format("LT")}`;
}

export function getFormattedTime(value: number) {
  const hours = Math.floor(value / 60);
  const min = Math.round(value - hours * 60);
  return { hours, min };
}

export function getFormattedDuration(
  durationInMinutes: number | null | undefined
) {
  if (durationInMinutes === undefined || durationInMinutes === null)
    return durationInMinutes;

  const hours = Math.floor(Math.abs(durationInMinutes) / 60);
  const minutes = Math.floor(Math.abs(durationInMinutes) % 60);
  return (
    (durationInMinutes < 0 ? "−" : "") +
    `${hours}:${padStart(minutes.toString(), 2, "0")}`
  );
}

export function getHumanReadableDuration(
  durationInSeconds: number,
  locale: string
) {
  // Default version
  // return moment.duration(durationInSeconds * 1000).locale(locale).humanize();

  // Version matching our duration select in UI
  const localeData = moment.localeData(locale);
  const [durationAmount, durationType] =
    getNormalizedDuration(durationInSeconds);

  switch (durationType) {
    case "hours":
      return localeData.relativeTime(
        durationAmount,
        true,
        durationAmount > 1 ? "hh" : "h",
        true
      );

    case "days":
      return localeData.relativeTime(
        durationAmount,
        true,
        durationAmount > 1 ? "dd" : "d",
        true
      );

    case "weeks":
      return localeData.relativeTime(
        durationAmount,
        true,
        durationAmount > 1 ? "ww" : "w",
        true
      );

    case "months":
      return localeData.relativeTime(
        durationAmount,
        true,
        durationAmount > 1 ? "MM" : "M",
        true
      );

    default:
      return localeData.invalidDate();
  }
}

export type DurationType = "hours" | "days" | "weeks" | "months" | "none";

const oneHour = 60 * 60;
const oneDay = 24 * oneHour;
const oneWeek = 7 * oneDay;
const oneMonth = 31 * oneDay;

export const getDurationInSeconds = (duration: {
  durationType: DurationType;
  durationAmount: number;
}) => {
  switch (duration.durationType) {
    case "hours":
      return duration.durationAmount * oneHour;

    case "days":
      return duration.durationAmount * oneDay;

    case "weeks":
      return duration.durationAmount * oneWeek;

    case "months":
      return duration.durationAmount * oneMonth;

    default:
      return 0;
  }
};

export const getNormalizedDuration = (
  duration: number | null
): [amount: number, unit: DurationType] => {
  if (!duration) {
    return [0, "none"];
  }

  if (duration % oneMonth === 0) {
    return [duration / (oneMonth * 1.0), "months"];
  }

  if (duration % oneWeek === 0) {
    return [duration / (oneWeek * 1.0), "weeks"];
  }

  if (duration % oneDay === 0) {
    return [duration / (oneDay * 1.0), "days"];
  }

  return [duration / (oneHour * 1.0), "hours"];
};

// extracts hours:minutes from time string returned from db: '06:00:00+02' -> '06:00'
export const parseTime = (
  time: string
): [number, number, number, number] | null => {
  const m = moment.parseZone(time, "HH:mm:ssZZ");
  if (!m.isValid()) return null;
  return [m.hours(), m.minutes(), m.seconds(), m.utcOffset()];
};

// converts time as "7:00" -> "07:00"
export const normalizeTime = <T extends string | undefined | null>(
  time: T
): T extends string ? string : undefined => {
  // @ts-ignore
  return time
    ? time.split(":")[0].padStart(2, "0") +
        ":" +
        time.split(":")[1].padStart(2, "0")
    : undefined;
};
