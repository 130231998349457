import { FormatIcu } from "@tolgee/format-icu";
import { LanguageDetector, LanguageStorage, Tolgee } from "@tolgee/react";
import { BackendFetch } from "@tolgee/web";
import { getSupportedSystemLocalCode } from "./utils";

export const createTolgeeBrowserInstance = (tolgeeCdnUrl: string) => {
  const tolgee = Tolgee()
    // .use(DevTools())
    .use(LanguageDetector())
    .use(LanguageStorage())
    .use(
      BackendFetch({
        prefix: tolgeeCdnUrl,
        getPath: ({ namespace, language, prefix }) => {
          return `${prefix}/${namespace}/${getSupportedSystemLocalCode(
            language
          )}.json`;
        },
      })
    )
    .use(FormatIcu())
    .init({
      fallbackNs: false,
      defaultNs: "Global",
      defaultLanguage: "en",
      fallbackLanguage: "en",
      availableLanguages: ["en", "de", "fr", "ru", "pl", "nl"],
    });

  // Enable dev tools locally
  // if (DEPLOY_ENV === "local") {
  //   tolgee.addPlugin(
  //     DevTools({
  //       credentials: {
  //         apiUrl: import.meta.env.VITE_TOLGEE_API_URL,
  //         apiKey: import.meta.env.VITE_TOLGEE_API_KEY,
  //       },
  //     })
  //   );
  // }
  // if (
  //   !Capacitor.isNativePlatform() &&
  //   (DEPLOY_ENV === "dev" || DEPLOY_ENV === "stg")
  // ) {
  //   import("@tolgee/web/tools").then(module => {
  //     tolgee.addPlugin(module.InContextTools());
  //     tolgee.addPlugin(module.ContextUi());
  //   });
  // }

  return tolgee;
};
