import React from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import {
  OverridableComponent,
  OverrideProps,
} from "@mui/material/OverridableComponent";
import { ExtendButtonBase } from "@mui/material/ButtonBase";
import { MenuItemTypeMap } from "@mui/material/MenuItem/MenuItem";

interface Props {
  icon?: React.ReactNode;
  inset?: boolean;
}

type MenuItemWithIconProps = OverrideProps<
  MenuItemTypeMap<
    Props & { button?: true },
    MenuItemTypeMap["defaultComponent"]
  >,
  MenuItemTypeMap["defaultComponent"]
>;

// @ts-ignore `button` is not compatible
export const MenuItemWithIcon: OverridableComponent<
  MenuItemTypeMap<
    Props & { button: false },
    MenuItemTypeMap["defaultComponent"]
  >
> &
  ExtendButtonBase<
    MenuItemTypeMap<
      Props & { button?: true },
      MenuItemTypeMap["defaultComponent"]
    >
  > = React.forwardRef<HTMLLIElement, MenuItemWithIconProps>(
  ({ icon, inset, children, ...props }, ref) => (
    <MenuItem ref={ref} {...props}>
      {icon && <ListItemIcon style={{ minWidth: 36 }}>{icon}</ListItemIcon>}
      <ListItemText style={{ marginTop: 0, marginBottom: 0 }} inset={inset}>
        {children}
      </ListItemText>
    </MenuItem>
  )
);
