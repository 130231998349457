import { Dialog } from "@mui/material";
import React from "react";
import { useScreenWidth } from "../hooks/useScreenWidth";
import { ModalOpenButton } from "../modal/ModalOpenButton";
import { makeStyles } from "../styles";
import { Image } from "./Image";

interface Props
  extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, "loading"> {
  title: string;
  loading?: boolean;
  disabled?: boolean;
  handleDelete?: () => void;
  borderRadius?: React.CSSProperties["borderRadius"];
  renderDeleteButton?: (button: React.ReactNode) => React.ReactNode;
}

export const ImageWithPreview = ({
  title,
  loading,
  disabled,
  handleDelete,
  borderRadius,
  style,
  renderDeleteButton,
  ...imgAttributes
}: Props) => {
  const { isMinTablet } = useScreenWidth();

  const image = (
    <Image
      title={title}
      loading={loading}
      disabled={disabled}
      handleDelete={handleDelete}
      borderRadius={borderRadius}
      style={{ ...style, cursor: "pointer" }}
      renderDeleteButton={renderDeleteButton}
      {...imgAttributes}
    />
  );

  // no need to show modal dialog for mobile devices
  return isMinTablet ? (
    <ModalOpenButton
      Modal={ImageDialog}
      modalProps={{ ...imgAttributes, title }}
    >
      {image}
    </ModalOpenButton>
  ) : (
    image
  );
};

interface ImageDialogProps
  extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, "loading"> {
  title?: string;
  handleClose: () => void;
}

const ImageDialog = ({
  handleClose,
  title,
  style,
  ...imgAttributes
}: ImageDialogProps) => {
  const { classes } = useStyles();

  return (
    <Dialog open onClose={handleClose} classes={classes} maxWidth="md">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          alt={title}
          {...imgAttributes}
          style={{ objectFit: "contain", maxWidth: "100%", maxHeight: "100%" }}
        />
      </div>
    </Dialog>
  );
};

const useStyles = makeStyles()({
  paper: {
    border: "none",
    backgroundColor: "transparent",
  },
});
