import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton } from "@mui/material";
import React from "react";
import { useIconButtonLightStyles } from "../button/useIconButtonStyles";

const transparent = {
  backgroundImage: `linear-gradient(45deg, #eee 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, #eee 75%),
      linear-gradient(45deg, transparent 75%, #eee 75%),
      linear-gradient(45deg, #eee 25%, #fff 25%)`,
  backgroundSize: "16px 16px",
  backgroundPosition: "0 0, 0 0, -8px -8px, 8px 8px",
};

interface Props
  extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, "loading"> {
  title: string;
  loading?: boolean;
  disabled?: boolean;
  handleDelete?: () => void;
  borderRadius?: React.CSSProperties["borderRadius"];
  renderDeleteButton?: (button: React.ReactNode) => React.ReactNode;
}

const defaultRenderButton = (button: React.ReactNode) => button;

export const Image = ({
  title,
  loading,
  disabled,
  handleDelete,
  borderRadius,
  style,
  renderDeleteButton = defaultRenderButton,
  ...imgAttributes
}: Props) => {
  const { classes: classesIconButtonLight } = useIconButtonLightStyles();

  return (
    <Box
      position="relative"
      display="flex"
      height="100%"
      overflow="hidden"
      flexDirection="column"
      justifyContent="center"
      style={{
        maxHeight: "min-content",
        borderRadius,
        ...transparent,
        ...(handleDelete ? { minHeight: 72 } : { minHeight: 0 }),
      }}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <img
        alt={title}
        {...imgAttributes}
        style={{
          objectFit: "contain",
          width: "100%",
          height: "auto",
          borderRadius: "inherit",
          ...style,
          // cursor: "pointer",
        }}
      />
      {handleDelete &&
        renderDeleteButton(
          <Box position="absolute" right="12px" bottom="12px">
            <IconButton
              color="primary"
              classes={classesIconButtonLight}
              onClick={handleDelete}
              disabled={loading || disabled}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
    </Box>
  );
};
