import { TextFieldProps, TextField } from "@mui/material";
import React from "react";
import Input from "react-phone-number-input/input";

interface Props
  extends Omit<TextFieldProps["inputProps"], "value" | "onChange"> {
  id?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  fullWidth?: boolean;
  error?: string;
  disabled?: boolean;
  value: string;
  onChange(newValue: string): void;
  variant?: TextFieldProps["variant"];
}

export const PhoneInput = ({
  id,
  name,
  label,
  placeholder,
  required,
  fullWidth,
  error,
  value,
  onChange,
  disabled,
  ...props
}: Props) => {
  return (
    <Input
      // @ts-ignore
      inputComponent={InnerInput}
      value={value}
      onChange={newValue => {
        onChange(newValue || "");
      }}
      {...props}
      id={id}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      fullWidth={fullWidth}
      error={error}
      disabled={disabled}
    />
  );
};

type InnerInputProps = TextFieldProps["inputProps"] & {
  label?: string;
  required?: boolean;
  fullWidth?: boolean;
  error?: string;
};

/*
A custom inputComponent can be passed to render it instead of the standard DOM <input/>.
It must behave as a standard DOM <input/>: support ref, accept a string value, and call onChange(event) whenever it's changed.
 */
const InnerInput = React.forwardRef<HTMLInputElement, InnerInputProps>(
  (
    {
      disabled,
      error,
      required,
      fullWidth,
      label,
      variant,
      ...props
    }: InnerInputProps,
    forwardedRef
  ) => {
    return (
      <TextField
        disabled={disabled}
        error={Boolean(error)}
        helperText={error ?? undefined}
        inputProps={{ disabled, required, ...props }}
        inputRef={forwardedRef}
        label={label}
        required={required}
        fullWidth={fullWidth}
        variant={variant}
      />
    );
  }
);
