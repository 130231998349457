import { Stack, StackProps, SvgIconProps } from "@mui/material";
import React from "react";
import { CollapseButton } from "./CollapseButton";

interface Props extends Omit<StackProps, "title"> {
  isExpandable: boolean;
  isExpanded: boolean;
  setIsExpanded: (newExpanded: boolean) => void;
  title?: React.ReactElement;
  tabs?: React.ReactElement;
  ActionButton?: React.ReactNode;
  CollapsedHint?: React.ReactNode;
  iconButtonColor?: SvgIconProps["color"];
  fullWidth?: boolean;
}

export const CollapseHeader = ({
  ActionButton,
  CollapsedHint,
  isExpandable,
  isExpanded,
  setIsExpanded,
  title,
  tabs,
  iconButtonColor,
  fullWidth,
  ...props
}: Props) => {
  return (
    <Stack
      direction={"row"}
      alignItems="center"
      justifyContent="space-between"
      maxWidth={"100%"}
      minHeight="40px"
      width={"100%"}
      px={0}
      py={0.5}
      {...props}
    >
      <Stack direction={"row"} alignItems="center" spacing={0.5} flex={1}>
        {isExpandable ? (
          <CollapseButton
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            title={title}
            color={iconButtonColor}
            fullWidth={fullWidth}
          />
        ) : (
          title
        )}
        {tabs}
      </Stack>
      {(isExpandable && !isExpanded) || ActionButton ? (
        <Stack
          alignItems="center"
          direction={"row"}
          spacing={0.5}
          onMouseDown={e => {
            e.stopPropagation();
          }}
          onTouchStart={e => {
            e.stopPropagation();
          }}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {isExpandable && !isExpanded ? CollapsedHint : null}
          {ActionButton}
        </Stack>
      ) : null}
    </Stack>
  );
};
