import React from "react";

interface StackContextI {
  stack: Symbol[];
  pushToStack: (key: Symbol) => void;
  removeFromStack: (key: Symbol) => void;
}
const StackContext = React.createContext<StackContextI>({} as StackContextI);

export function ModalStackProvider({ children }: React.PropsWithChildren<{}>) {
  const [stack, setStack] = React.useState<Symbol[]>([]);

  const pushToStack = React.useCallback((key: Symbol) => {
    setStack(stack => [...stack, key]);
  }, []);

  const removeFromStack = React.useCallback((key: Symbol) => {
    setStack(stack => stack.filter(item => item !== key));
  }, []);

  return (
    <StackContext.Provider value={{ stack, pushToStack, removeFromStack }}>
      {children}
    </StackContext.Provider>
  );
}

export const useModalStack = (
  notInStack: boolean = false,
  onChange?: (isTop: boolean) => void
) => {
  const onChangeRef = React.useRef(onChange);
  onChangeRef.current = onChange;

  const { stack, pushToStack, removeFromStack } =
    React.useContext(StackContext);

  const key = React.useMemo(() => Symbol(), []);

  React.useEffect(() => {
    if (notInStack) return;

    pushToStack(key);
    return () => removeFromStack(key);
  }, [notInStack, pushToStack, removeFromStack, key]);

  const isTop = notInStack || stack[stack.length - 1] === key;

  React.useEffect(() => {
    onChangeRef.current?.(isTop);
  }, [isTop]);

  return { isTop };
};

export const useModalStackLength = () => {
  const { stack } = React.useContext(StackContext);
  return stack.length;
};
