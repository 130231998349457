import { StyledComponent } from "@emotion/styled";
import {} from "@mui/system";
import {
  ImageListItem as MuiImageListItem,
  ImageListItemProps as MuiImageListItemProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { transientOptions } from "../styles";

export const ImageListItem: StyledComponent<
  MuiImageListItemProps & {
    $clickable: boolean;
    $selected: boolean;
    $moreAmount?: number;
    $aspectRatio?: React.CSSProperties["aspectRatio"];
  }
> = styled(
  MuiImageListItem,
  transientOptions
)<{
  $clickable: boolean;
  $selected: boolean;
  $moreAmount?: number;
  $aspectRatio?: React.CSSProperties["aspectRatio"];
}>(({ $clickable, $selected, $moreAmount, $aspectRatio, theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
  position: "relative",
  aspectRatio: $aspectRatio ?? "1",
  ...($clickable ? { cursor: "pointer" } : undefined),
  ...($selected
    ? {
        "&:before": {
          content: `""`,
          backgroundColor:
            theme.palette.mode === "dark"
              ? "rgba(0, 0, 0, 0.7)"
              : "rgba(80, 135, 195, 0.7)",
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 4,
          pointerEvents: "none",
          cursor: "default",
        },
      }
    : undefined),
  ...($moreAmount && $moreAmount > 0
    ? {
        "&:after": {
          content: `"+${$moreAmount}"`,
          display: "block",
          color: theme.palette.common.white,
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: `translate(-50%, -50%)`,
          zIndex: 5,
          pointerEvents: "none",
          fontSize: "1.15rem",
          fontWeight: 500,
          padding: "0 4px",
          borderRadius: "2px",
        },
      }
    : undefined),
}));
