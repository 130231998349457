import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { Share } from "@capacitor/share";
import moment from "moment";
import React from "react";

export const trimFileExtension = (fileName: string) =>
  fileName.split(".").slice(0, -1).join(".");

export const getFileExtension = (fileName: string) =>
  fileName.split(".").slice(-1);

const toDataURL = async (url: string) => {
  const blob = await fetch(url).then(response => response.blob());
  return URL.createObjectURL(blob);
};

const blobToBase64 = (blob: Blob) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result) {
        resolve(reader.result.toString());
      } else {
        reject(new Error(`Cannot read blob file: ${reader.error?.message}`));
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });

const fileToBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result) {
        resolve(reader.result.toString());
      } else {
        reject(new Error(`Cannot read file: ${reader.error?.message}`));
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

const downloadUrlDesktop = (
  url: string,
  fileName: string,
  preferOpen: boolean = false
) => {
  if (preferOpen) {
    const tab = window.open(url, "_blank");
    if (tab) tab.focus();
  } else {
    const a = document.createElement("a");
    a.href = url;
    a.style.display = "none";
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
    });
  }
};

export const useDownloadFile = () => {
  const urlRef = React.useRef<string | null>(null);
  const revokeFileUrl = React.useRef<() => void>();
  revokeFileUrl.current = () => {
    if (urlRef.current) {
      try {
        URL.revokeObjectURL(urlRef.current);
      } catch (e) {
        // nothing to do
      } finally {
        urlRef.current = null;
      }
    }
  };
  const [downloading, setDownloading] = React.useState<boolean>(false);

  const downloadDesktopFromUrl = async (
    url: string,
    fileName: string,
    preferOpen: boolean = false
  ) => {
    try {
      revokeFileUrl.current?.();
      const blobURL = await toDataURL(url);
      urlRef.current = blobURL;
      downloadUrlDesktop(blobURL, fileName, preferOpen);
    } catch (e) {
      // fallback
      downloadUrlDesktop(url, fileName, preferOpen);
    }
  };

  const downloadDesktopAsBlob = async (
    blob: Blob,
    fileName: string,
    preferOpen: boolean = false
  ) => {
    const fileFullName = `${trimFileExtension(fileName)}_${moment().format(
      "YYYY-MM-DD_HH-mm-ss"
    )}.${getFileExtension(fileName)}`;
    revokeFileUrl.current?.();
    const blobURL = URL.createObjectURL(blob);
    urlRef.current = blobURL;
    downloadUrlDesktop(blobURL, fileFullName, preferOpen);
  };

  const downloadDesktopAsFile = async (
    file: File,
    fileName: string,
    preferOpen: boolean = false
  ) => {
    const fileFullName = `${trimFileExtension(fileName)}_${moment().format(
      "YYYY-MM-DD_HH-mm-ss"
    )}.${getFileExtension(fileName)}`;
    revokeFileUrl.current?.();
    const fileURL = URL.createObjectURL(file);
    urlRef.current = fileURL;
    downloadUrlDesktop(fileURL, fileFullName, preferOpen);
  };

  const downloadMobileFromUrl = async (url: string, fileName: string) => {
    let fileLocalPath: string | null = null;
    try {
      const fileFullName = `${trimFileExtension(fileName)}_${moment().format(
        "YYYY-MM-DD_HH-mm-ss"
      )}.${getFileExtension(fileName)}`;

      const result = await Filesystem.downloadFile({
        url,
        method: "GET",
        dataType: "file",
        path: fileFullName,
        recursive: true,
        progress: false,
        directory: Directory.Cache,
      });

      if (!result.path)
        throw new Error("Download failed, please try again later");

      fileLocalPath = result.path.startsWith("file://")
        ? result.path
        : `file://${result.path}`;

      try {
        await Share.share({
          url: fileLocalPath,
        });
      } catch (e) {
        // nothing to do when user cancels it
      }
    } catch (e) {
      // fallback
      try {
        await Browser.open({ url, presentationStyle: "fullscreen" });
      } catch (e) {
        const tab = window.open(url, "_system");
        if (tab) tab.focus();
      }
    }
  };

  const downloadMobileAsBlob = async (blob: Blob, fileName: string) => {
    const fileFullName = `${trimFileExtension(fileName)}_${moment().format(
      "YYYY-MM-DD_HH-mm-ss"
    )}.${getFileExtension(fileName)}`;

    const result = await Filesystem.writeFile({
      data: await blobToBase64(blob),
      path: fileFullName,
      recursive: true,
      directory: Directory.Cache,
      // The encoding to write the file in. If not provided, data is written as base64 encoded. Pass Encoding.UTF8 to write data as string
      encoding: undefined, // !important since data is in base64
    });

    if (!result.uri) throw new Error("Download failed, please try again later");

    const fileLocalPath = result.uri.startsWith("file://")
      ? result.uri
      : `file://${result.uri}`;

    try {
      await Share.share({
        url: fileLocalPath,
      });
    } catch (e) {
      // nothing to do when user cancels it
    }
  };

  const downloadMobileAsFile = async (file: File, fileName: string) => {
    const fileFullName = `${trimFileExtension(fileName)}_${moment().format(
      "YYYY-MM-DD_HH-mm-ss"
    )}.${getFileExtension(fileName)}`;

    const result = await Filesystem.writeFile({
      data: await fileToBase64(file),
      path: fileFullName,
      recursive: true,
      directory: Directory.Cache,
      // The encoding to write the file in. If not provided, data is written as base64 encoded. Pass Encoding.UTF8 to write data as string
      encoding: undefined, // !important since data is in base64
    });

    if (!result.uri) throw new Error("Download failed, please try again later");

    const fileLocalPath = result.uri.startsWith("file://")
      ? result.uri
      : `file://${result.uri}`;

    try {
      await Share.share({
        url: fileLocalPath,
      });
    } catch (e) {
      // nothing to do when user cancels it
    }
  };

  const downloadFileFromUrl = async (
    url: string,
    fileName: string,
    preferOpen: boolean = false
  ) => {
    if (downloading) return;

    setDownloading(true);
    try {
      if (Capacitor.isNativePlatform()) {
        await downloadMobileFromUrl(url, fileName);
      } else {
        await downloadDesktopFromUrl(url, fileName, preferOpen);
      }
    } catch (e) {
      // nothing to do
    } finally {
      setDownloading(false);
    }
  };

  const downloadFileAsBlob = async (
    blob: Blob,
    fileName: string,
    preferOpen: boolean = false
  ) => {
    if (downloading) return;

    setDownloading(true);
    try {
      if (Capacitor.isNativePlatform()) {
        await downloadMobileAsBlob(blob, fileName);
      } else {
        await downloadDesktopAsBlob(blob, fileName, preferOpen);
      }
    } catch (e) {
      // nothing to do
    } finally {
      setDownloading(false);
    }
  };

  const downloadFileAsFile = async (
    file: File,
    fileName: string,
    preferOpen: boolean = false
  ) => {
    if (downloading) return;

    setDownloading(true);
    try {
      if (Capacitor.isNativePlatform()) {
        await downloadMobileAsFile(file, fileName);
      } else {
        await downloadDesktopAsFile(file, fileName, preferOpen);
      }
    } catch (e) {
      // nothing to do
    } finally {
      setDownloading(false);
    }
  };

  // revoke created object urls
  React.useEffect(() => {
    return () => {
      revokeFileUrl.current?.();
    };
  }, []);

  return {
    downloadFileFromUrl,
    downloadFileAsBlob,
    downloadFileAsFile,
    downloading,
  };
};
