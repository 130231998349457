import { trace } from "@opentelemetry/api";
import ellipsize from "ellipsize";
import { freeze } from "immer";
import latinize from "latinize";
import {
  chunk,
  escapeRegExp,
  extend,
  groupBy,
  head,
  isNil,
  isNull,
  isUndefined,
  keyBy,
  ListIteratee,
  sortBy,
  zip,
} from "lodash";
import { stripHtml } from "string-strip-html";

export type IdentityWithLocale = {
  organisationId: string;
  userId: string;
  locale: string;
  restrictedPermissions?: Array<string>;
  restrictedGraphqlQueryFields?: Array<string>;
  restrictedGraphqlMutationFields?: Array<string>;
};
export type Identity = IdentityWithLocale;

export const DEFAULT_IDENTITY_LOCALE = "en";
export const UNKNOWN_DEFAULT_IDENTITY_LOCALE = DEFAULT_IDENTITY_LOCALE;

export type Author = { organisationId: string; userId: string };
export type Phone = {
  id: string;
  main: boolean;
  number: string;
  type: "HOME" | "HOTLINE" | "MOBILE" | "PRIVATE" | "WORK";
};

export type DateISOString = string;

export type DateOrISOString = Date | DateISOString;

export function assertNever(x: never, message?: string): never {
  throw new Error(message ?? "Unexpected object: " + x);
}

export function decodeGlobalId<T extends string>(
  encodedGlobalId: string
): { id: string; type?: T } {
  return { id: encodedGlobalId };
}

export function encodeGlobalId<T extends string>(type: T, id: string) {
  return id;
}

export function stringOrThrow(str: string | null | undefined): string {
  if (str === undefined || str === null) throw new Error("undefined or null");

  return str;
}

export const UTC_OFFSET = 120;

export function pick<T extends object, K extends keyof T>(
  base: T,
  ...keys: K[]
): Pick<T, K> {
  const entries = keys.map(key => [key, base[key]]);
  return Object.fromEntries(entries);
}

export function omit<T extends object, K extends keyof T>(
  base: T,
  ...keys: K[]
): Omit<T, K> {
  let obj = { ...base };

  for (let key of keys) {
    delete obj[key];
  }

  return obj;
}

export function findOrThrow<T>(
  arr: Array<T>,
  predicate: (val: T) => boolean,
  notFoundMessage?: string
): NonNullable<T> {
  const result = arr.find(predicate);

  if (result) return result;

  throw new Error(notFoundMessage ?? `not found`);
}

export type TsTag<T, Tag> = T & {
  __TS_ONLY_DO_NOT_USE__: Tag;
};

export const MAX_LIMIT = 99999;

export type AggregateLoadResult<T> =
  | { type: "found"; aggregate: T }
  | { type: "not_found" };

export function foundOrThrow<T>(
  loadResult: AggregateLoadResult<T>,
  errorMessage?: string
) {
  if (loadResult.type === "found") return loadResult.aggregate;

  throw new Error(errorMessage ?? "not found");
}

export function throwIfNull<T>(
  // value: (T extends Promise<infer X> ? never : T) | null
  value: T | null,
  errorMessage?: string
): T {
  if (value === null)
    throw new Error(errorMessage ?? `not expected null value`);

  return value;
}

export function throwIfNil<T>(
  // value: (T extends Promise<infer X> ? never : T) | null
  value: T | undefined | null,
  errorMessage?: string
): T {
  if (isNil(value)) throw new Error(errorMessage ?? `not expected nil value`);

  return value;
}

export function throwIfEmptyString(
  value: string,
  errorMessage?: string
): string {
  if (value.length === 0)
    throw new Error(errorMessage ?? `not expected empty string`);

  return value;
}

export function throwIfUndefined<T>(
  // value: (T extends Promise<infer X> ? never : T) | null
  value: T | undefined,
  errorMessage?: string
): T {
  if (isUndefined(value))
    throw new Error(errorMessage ?? `not expected undefined value`);

  return value;
}

export function throwIfAnyNull<T>(
  // value: (T extends Promise<infer X> ? never : T) | null
  values: Array<T | null>,
  errorMessage?: string
): Array<T> {
  const cleanedValues = values.flatMap(value => (value ? [value] : []));

  if (values.length !== cleanedValues.length) {
    throw new Error(errorMessage ?? `not expected null value in "${values}"`);
  }

  return cleanedValues;
}

export function headOrThrow<T>(values: Array<T>, errorMessage?: string): T {
  return throwIfUndefined(head(values), errorMessage);
}

export function valuesByKeys<T, K extends string | number>(
  values: T[],
  keys: K[],
  valueKey: (value: T) => K
): Array<T | null> {
  const valuesMap = keyBy(values, value => valueKey(value));
  return keys.map(key => valuesMap[key] ?? null);
}

export function groupedValuesByKeys<T, K extends string | number>(
  values: T[],
  keys: K[],
  valueKey: (value: T) => K
): Array<T[]> {
  const valuesMap = groupBy(values, value => valueKey(value));

  return keys.map(key => valuesMap[key] ?? []);
}

export function sortedGroupedValuesByKeys<T, K extends string | number>(
  values: T[],
  keys: K[],
  valueKey: (value: T) => K,
  ...iteratees: [ListIteratee<T>, ...ListIteratee<T>[]]
): Array<T[]> {
  const valuesMap = groupBy(values, value => valueKey(value));

  return keys.map(key => sortBy(valuesMap[key], ...iteratees) ?? []);
}

export function isPicture(mimeType: string) {
  return mimeType.toLowerCase().startsWith("image");
}

export function getPictures<A extends { url: string; mimeType: string }>(
  attachments: A[]
): A[] {
  return attachments.filter(e => isPicture(e.mimeType));
}

export function getFiles<A extends { url: string; mimeType: string }>(
  attachments: A[]
): A[] {
  return attachments.filter(e => !isPicture(e.mimeType));
}

export function getNameWithCopy(name: string, copySuffix: string) {
  // const regexp = new RegExp(`^(.*) - ${copySuffix}( [0-9]+)?$`, "gi");
  // const [, originalName] = name.match(regexp) ?? [];
  // return `${originalName ?? name} - ${copySuffix}`;
  return `${name} - ${copySuffix}`;
}

export type ExactShape<T, Shape> = T extends Shape
  ? Exclude<keyof T, keyof Shape> extends never
    ? T
    : never
  : never;

type primitive = Date | string | number | boolean | undefined | null;

export type DeepReadonlyObject<T> = {
  readonly [P in keyof T]: DeepReadonlyObject<T[P]>;
};

interface DeepReadonlyArray<T> extends ReadonlyArray<DeepReadonly<T>> {}

export type DeepReadonly<T> = T extends primitive
  ? T
  : T extends Array<infer U>
    ? DeepReadonlyArray<U>
    : DeepReadonlyObject<T>;

// export type DeepReadonly<T> = {
//   readonly [P in keyof T]: DeepReadonly<T[P]>;
// };

export function wrapErrorWithContext(
  cause: any,
  context: Record<string, string>
) {
  const message = [
    ...(cause?.message?.split(";") ?? []),
    ...Object.entries(context).map(([key, val]) => `${key}: ${val}`),
  ].join(";");

  throw new Error(message, { cause });
}

export function enhanceWithFullName<
  T extends { firstname: string; familyname: string },
>(subject: T): T & { fullname: string } {
  return {
    ...subject,
    fullname: `${subject.firstname} ${subject.familyname}`.trim(),
  };
}

export type DeepPartial<TObject> = TObject extends object
  ? {
      [P in keyof TObject]?: DeepPartial<TObject[P]>;
    }
  : TObject;

export function notNull<T>(value: T): value is Exclude<T, null> {
  return value !== null;
}

export function notUndefined<T>(value: T): value is Exclude<T, undefined> {
  return value !== undefined;
}

export function notNullNorUndefined<T>(
  value: T
): value is Exclude<T, null | undefined> {
  return value !== null && value !== undefined;
}

export function requiredValue<T, U>(value: T | undefined, valueOnUndefined: U) {
  return isUndefined(value) ? valueOnUndefined : value;
}

export function nonNilValue<T, U>(
  value: T | null | undefined,
  valueOnUndefined: U,
  valueOnNull: U
) {
  return isUndefined(value)
    ? valueOnUndefined
    : isNull(value)
      ? valueOnNull
      : value;
}

export const parseNumber = (
  number: string,
  prefix: string,
  minLength: number
): number => {
  const regexp = new RegExp(
    `^${prefix
      .replace(/<YYYY>/gi, "[0-9]{4}")
      .replace(/<YY>/gi, "[0-9]{2}")
      .replace(/<MM>/gi, "[0-9]{2}")
      .replace(/<DD>/gi, "[0-9]{2}")}([0-9]+)$`
  );
  const [, counter] = number.match(regexp) ?? [];
  if (!counter)
    throw new Error(
      `Number ${number} does not match provided prefix: ${prefix}`
    );

  if (counter.length < minLength)
    throw new Error(
      `Counter ${counter} length is smaller than min length: ${minLength}`
    );

  return parseInt(counter.replace(/^0+/, ""), 10);
};

export async function wait(ms: number) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, ms);
  });
}

export function zipOrThrow<T, U>(array1: Array<T>, array2: Array<U>): [T, U][] {
  if (array1.length !== array2.length) {
    throw new Error(
      `Arrays have different lengths ${array1.length} and ${array2.length}`
    );
  }

  return zip(array1, array2) as [T, U][];
}

export function filterNonNull<T>(array: Array<T | null>): Array<T> {
  return array.filter(notNull);
}

export const stripHtmlTags = (
  html: string = "",
  keepLineBreaks: boolean = true
): string => {
  return stripHtml(
    keepLineBreaks
      ? html
          .replace(/<br ?\/?>/gi, `<br/>\n`)
          .replace(/<\/(br|p|li|h1|h2|h3|h4|h5|h6)>/gi, `</$1>\n`)
      : html
  ).result;
};

export const nl2br = (text: string) => text.replace(/(?:\r\n|\r|\n)/g, "<br/>");

export const truncateText = (text: string, maxSymbolsAmount: number): string =>
  ellipsize(text, maxSymbolsAmount);

export const deepFreeze = <T extends {}>(obj: T) => {
  const span = trace
    .getTracer(process.env.SERVICE_NAME ?? "undefined")
    .startSpan("msys_deepFreeze");

  try {
    return freeze(obj, true);
  } finally {
    span.end();
  }
};

/**
 * Assume a float with country specific thousand grouper and country specific decimal, removes the grouper and replaces the decimal with "."
 */
export function parseCountryFloat(
  value: string,
  opts: { decimal: string; grouper?: string }
) {
  let cleanedValue = value;

  if (opts.grouper) {
    cleanedValue = cleanedValue.replace(
      new RegExp(escapeRegExp(opts.grouper), "g"),
      ""
    );
  }

  cleanedValue = cleanedValue.replace(
    new RegExp(escapeRegExp(opts.decimal), "g"),
    "."
  );

  const parsedValue = parseFloat(cleanedValue);

  if (Number.isNaN(parsedValue) || !Number.isFinite(parsedValue)) {
    throw new Error(`Not a valid number "${parsedValue}" from "${value}"`);
  }

  return parsedValue;
}

export class ConcurrentDocumentModificationError extends Error {}

export async function tryFn<T>(
  tryFn: () => Promise<T>,
  retryIfFn: (err: unknown) => boolean,
  maxTries: number,
  // msys/common does not depend on msys/logger
  logger: {
    info: (...args: unknown[]) => void;
    error: (...args: unknown[]) => void;
  }
) {
  let tryCnt = 0;

  while (tryCnt < maxTries) {
    if (tryCnt > 0) {
      logger.info(">>>>>>>>> RETRY", tryCnt);
    }
    tryCnt++;
    try {
      const res = await tryFn();

      if (tryCnt > 1) {
        logger.info(">>>>>>>>> RETRY succeeded", tryCnt);
      }

      return res;
    } catch (err) {
      if (retryIfFn(err)) {
        logger.error(">>>>>>>>> ERROR", err);
        continue;
      }
      throw err;
    }
  }

  throw new Error();
}

export let runningResponseDeferredCnt = 0;

export async function runResponseDeferredFn(fn: () => Promise<unknown>) {
  runningResponseDeferredCnt++;

  // we do NOT await here because we do not want the user's http request to wait
  await (async () => {
    try {
      await fn();
    } finally {
      runningResponseDeferredCnt--;
    }
  })();
}

// modify the behavior for German umlauts
extend(latinize.characters, {
  Ä: "Ae",
  Ö: "Oe",
  Ü: "Ue",
  ä: "ae",
  ö: "oe",
  ü: "ue",
});
export function getFileName(name: string, spacer: string = "-") {
  return latinize(name)
    .replace(/[^0-9a-zA-Z ]/gi, "")
    .replace(/\s+/gi, spacer);
}

/**
 * Chunk an async operation over an array.
 *
 * @returns The result per chunk.
 */
export async function doChunkedOperation<T, S>(
  array: T[],
  chunkSize: number,
  fn: (chunkedArray: T[]) => Promise<S>
): Promise<S[]> {
  const results: S[] = [];

  for (const chunkedArray of chunk(array, chunkSize)) {
    const chunkResult = await fn(chunkedArray);

    results.push(chunkResult);
  }

  return results;
}

export type AskWhen =
  | "onQuoteCreate"
  | "onQuoteRefinement"
  | "onEmbeddableWizard";
export type AskWhom = "contractee" | "contractor";

export function getLocaleLanguageAndCountry(locale: string): {
  language: string;
  country: string | undefined;
} {
  const [language, country] = locale.split("-");

  return { language, country };
}

export type QuantityUnit =
  | "cm"
  | "cm2"
  | "cm3"
  | "doz"
  | "gr"
  | "hl"
  | "hr"
  | "kg"
  | "km"
  | "l"
  | "lump_sum"
  | "m"
  | "m2"
  | "m3"
  | "mm"
  | "pair"
  | "piece"
  | "set"
  | "t";

export type PickWithPrefix<T extends {}, P extends string> = Pick<
  T,
  FilterStartingWith<keyof T, P>
>;

export type FilterStartingWith<
  Set extends string | number | symbol,
  Needle extends string,
> = Set extends `${Needle}${infer _X}` ? Set : never;

export function getFilenameFromContentDisposition(
  contentDisposition: string = ""
) {
  const regex = /filename\*?=([^']*'')?([^;]*)/;

  return regex.exec(contentDisposition)?.[2].replaceAll('"', "");
}

export type Capabilities =
  | "ADVANCED_SETTINGS"
  | "BRANDING"
  | "BUILDINGS"
  | "EXECUTING"
  | "FEATURE_FLAGS"
  | "INTEGRATIONS"
  | "INVOICING"
  | "MARKETPLACE"
  | "ORDERING"
  | "PLANNING"
  | "PIM"
  | "PUBLIC_PROFILE"
  | "QUOTING"
  | "REFERRAL"
  | "SHOP"
  | "TEMPLATING"
  | "TIME_TRACKING";

export async function streamToBuffer(stream: NodeJS.ReadableStream) {
  const buffers: Buffer[] = [];

  // node.js readable streams implement the async iterator protocol
  for await (const data of stream) {
    buffers.push(Buffer.from(data));
  }

  return Buffer.concat(buffers);
}

export type BufferChunkIterator = Generator<Buffer>;

export function* bufferToBufferChunkIterator(
  buffer: Buffer,
  chunkSize: number
): BufferChunkIterator {
  for (let i = 0; i < buffer.length; i = i + chunkSize) {
    yield buffer.subarray(i, i + chunkSize);
  }
}

export function* arrayToChunkIterator<T>(
  array: T[],
  chunkSize: number
): Generator<T[]> {
  for (const c of chunk(array, chunkSize)) {
    yield c;
  }
}

export type Nullable<T> = { [P in keyof T]: T[P] | null };

export type RequiredNotNull<T> = Required<T> & {
  [P in keyof T]: NonNullable<T[P]>;
};

type MissingPermissions = {
  __typename: "MissingPermissions";
};

export type MissingCapabilities = {
  __typename: "MissingCapabilities";
};

function isNotError<
  Value extends { __typename: string },
  MP extends MissingPermissions,
  MC extends MissingCapabilities,
>(value: Value): value is Exclude<Value, MC | MP> {
  return (
    value.__typename !== "MissingCapabilities" &&
    value.__typename !== "MissingPermissions"
  );
}

export function getDataOrNull<
  Value extends { __typename: string },
  MP extends MissingPermissions,
  MC extends MissingCapabilities,
>(value: Value | undefined): Exclude<Value, MC | MP> | null {
  if (value === undefined) return null;
  return isNotError<Value, MP, MC>(value) ? value : null;
}

export function getDataOrThrow<
  Value extends { __typename: string },
  MP extends MissingPermissions,
  MC extends MissingCapabilities,
>(value: Value): Exclude<Value, MC | MP> {
  if (!isNotError<Value, MP, MC>(value)) throw new Error(value.__typename);

  return value;
}

export function dateOrIsoString2DateIsoString(
  value: DateOrISOString
): DateISOString {
  return typeof value === "string" ? value : value.toISOString();
}
