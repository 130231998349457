import { Box, Typography, type TypographyProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { CollapseChip } from "../CollapseChip";
import { transientOptions } from "../styles";

export const TypographyWithMaxNumberOfLines: React.ComponentType<
  TypographyProps & {
    $maxLines: number;
    variant: Exclude<
      React.ComponentProps<typeof Typography>["variant"],
      undefined | "inherit"
    >;
  }
> = styled(
  Typography,
  transientOptions
)<{
  $maxLines: number;
  variant: Exclude<
    React.ComponentProps<typeof Typography>["variant"],
    undefined | "inherit"
  >;
}>(({ theme, $maxLines, variant }) =>
  $maxLines > 1
    ? {
        display: "-webkit-box",
        overflow: "hidden",
        WebkitLineClamp: $maxLines,
        WebkitBoxOrient: "vertical",
        maxHeight: `calc(${theme.typography[variant].fontSize} * ${theme.typography[variant].lineHeight} * ${$maxLines})`,
      }
    : $maxLines === 1
    ? {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxHeight: `calc(${theme.typography[variant].fontSize} * ${theme.typography[variant].lineHeight})`,
      }
    : {}
);

interface TypographyWithIconProps extends TypographyProps {
  icon?: React.ReactElement;
  count?: number;
  fullWidth?: boolean;
}

export const TypographyWithIcon = ({
  icon,
  count,
  fullWidth,
  children,
  ...props
}: TypographyWithIconProps) => {
  return (
    <Typography
      sx={{
        display: "inline-flex",
        alignItems: "center",
        [".MuiSvgIcon-root, .MuiIcon-root"]: {
          marginRight: "4px",
          fontSize: "1.375rem",
        },
        ...(fullWidth ? { width: "100%" } : undefined),
      }}
      {...props}
    >
      {icon}
      {children}
      {count !== undefined && (
        <Box
          display="flex"
          alignItems="center"
          ml={1}
          component="span"
          height="1em"
        >
          <CollapseChip label={count} />
        </Box>
      )}
    </Typography>
  );
};
