import { styled } from "@mui/material/styles";
import React from "react";

export const underlineDoubleStyle: React.CSSProperties = {
  textDecorationLine: "underline",
  textDecorationStyle: "double",
  WebkitTextDecorationLine: "underline",
  WebkitTextDecorationStyle: "double",
  MozTextDecorationLine: "underline",
  MozTextDecorationStyle: "double",
};

export const UnderlineDouble: React.ComponentType<
  React.HTMLAttributes<HTMLSpanElement>
> = styled("span")({
  textDecorationLine: "underline",
  textDecorationStyle: "double",
});
