import { Locale } from "@msys/tolgee";

type YupLocale = "en" | "de" | "fr" | "ru" | "pl" | "nl";

export const localeToYupLocale: Record<Locale, YupLocale> = {
  "en-GB": "en",
  de: "de",
  fr: "fr",
  ru: "ru",
  pl: "pl",
  nl: "nl",
};

/* eslint-disable no-template-curly-in-string */
export const yupTranslations: Record<YupLocale, object> = {
  en: {
    mixed: {
      default: "${path} is invalid",
      required: "${path} is a required field",
      oneOf: "${path} must be one of the following values: ${values}",
      notOneOf: "${path} must not be one of the following values: ${values}",
    },
    string: {
      length: "${path} must be exactly ${length} characters",
      min: "${path} must be at least ${min} characters",
      max: "${path} must be at most ${max} characters",
      matches: '${path} must match the following: "${regex}"',
      email: "${path} must be a valid email",
      url: "${path} must be a valid URL",
      lowercase: "${path} must be a lowercase string",
      uppercase: "${path} must be a upper case string",
    },
    number: {
      min: "${path} must be greater than or equal to ${min}",
      max: "${path} must be less than or equal to ${max}",
      lessThan: "${path} must be less than ${less}",
      moreThan: "${path} must be greater than ${more}",
      positive: "${path} must be a positive number",
      negative: "${path} must be a negative number",
      integer: "${path} must be an integer",
    },
    date: {
      min: "${path} field must be later than ${min}",
      max: "${path} field must be at earlier than ${max}",
    },
    array: {
      min: "${path} field must have at least ${min} items",
      max: "${path} field must have less than or equal to ${max} items",
    },
  },

  de: {
    mixed: {
      default: "${path} ist ungültig",
      required: "${path} ist ein Pflichtfeld",
      oneOf: "${path} muss einer der folgenden Werte sein: ${values}",
      notOneOf: "${path} darf nicht einer der folgenden Werte sein: ${values}",
    },
    string: {
      length: "${path} muss genau ${length} Zeichen lang sein",
      min: "${path} muss mindestens ${min} Zeichen lang sein",
      max: "${path} darf höchstens ${max} Zeichen lang sein",
      email: "${path} muss eine gültige E-Mail sein",
      url: "${path} muss eine gültige URL sein",
      lowercase: "${path} darf nur aus Kleinbuchstaben bestehen",
      uppercase: "${path} darf nur aus Großbuchstaben bestehen",
    },
    number: {
      min: "${path} muss größer oder gleich ${min} sein",
      max: "${path} muss kleiner oder gleich ${max} sein",
      lessThan: "${path} muss kleiner als ${less} sein",
      moreThan: "${path} muss größer als ${more} sein",
      positive: "${path} muss eine positive Zahl sein",
      negative: "${path} muss eine negative Zahl sein",
      integer: "${path} muss eine ganze Zahl sein",
    },
    date: {
      min: "${path} muss später als ${min} sein",
      max: "${path} muss früher als ${max} sein",
    },
    array: {
      min: "${path} muss mindestens ${min} Elemente haben",
      max: "${path} darf höchstens ${max} Elemente haben",
    },
  },

  fr: {
    mixed: {
      default: "${path} est invalide",
      required: "${path} est un champ obligatoire",
      oneOf: "${path} doit être l'une des valeurs suivantes: ${values}",
      notOneOf:
        "${path} ne doit pas être l'une des valeurs suivantes: ${values}",
    },
    string: {
      length: "${path} doit être exactement caractères ${length}",
      min: "${path} doit être au moins caractères ${min}",
      max: "${path} doit être à la plupart des caractères ${max}",
      email: "${path} doit être un email valide",
      url: "${path} doit être une URL valide",
      lowercase: "${path} doit être une chaîne en minuscule",
      uppercase: "${path} doit être une chaîne de majuscules",
    },
    number: {
      min: "${path} doit être supérieur ou égal à ${min}",
      max: "${path} doit être inférieur ou égal à ${max}",
      lessThan: "${path} doit être inférieur à ${less}",
      moreThan: "${path} doit être supérieur à ${more}",
      positive: "${path} doit être un nombre positif",
      negative: "${path} doit être un nombre négatif",
      integer: "${path} doit être un entier",
    },
    date: {
      min: "${path} champ doit être postérieure à ${min}",
      max: "${path} champ doit être au plus tôt que ${max}",
    },
    array: {
      min: "${path} champ doit avoir au moins des éléments ${min}",
      max: "${path} champ doit avoir inférieur ou égal à ${max} articles",
    },
  },

  nl: {
    mixed: {
      default: "${path} is ongeldig",
      required: "${path} is een verplicht veld",
      oneOf: "${path} moet een van de volgende waarden zijn: ${values}",
      notOneOf: "${path} mag niet een van de volgende waarden zijn: ${values}",
    },
    string: {
      length: "${path} moet precies ${length} tekens lang zijn",
      min: "${path} moet ten minste ${min} tekens lang zijn",
      max: "${path} mag maximaal ${max} tekens lang zijn",
      email: "${pad} moet een geldig emailadres zijn",
      url: "${path} moet een geldige URL zijn",
      lowercase: "${path} mag alleen uit kleine letters bestaan",
      uppercase: "${path} mag alleen uit hoofdletters bestaan",
    },
    number: {
      min: "${path} moet groter zijn dan of gelijk aan ${min}",
      max: "${path} moet kleiner zijn dan of gelijk aan ${max}",
      lessThan: "${path} moet kleiner zijn dan ${less}",
      moreThan: "${path} moet groter zijn dan ${more}",
      positive: "${path} moet een positief getal zijn",
      negative: "${path} moet een negatief getal zijn",
      integer: "${path} moet een integer zijn",
    },
    date: {
      min: "${path} moet later zijn dan ${min}",
      max: "${path} moet eerder zijn dan ${max}",
    },
    array: {
      min: "${path} moet ten minste ${min} elementen hebben",
      max: "${path} moet ten hoogste ${max} elementen hebben",
    },
  },

  ru: {
    mixed: {
      default: "${path} является недействительным",
      required: "${path} является обязательным полем",
      oneOf: "${path} должен быть одним из следующих значений: ${values}",
      notOneOf: "${path} не должен быть одним из следующих значений: ${values}",
    },
    string: {
      length: "${path} должен быть ровно ${length} символов",
      min: "${path} должен быть, по крайней мере, ${min} символов",
      max: "${path} должна быть не более ${max} символов",
      email: "${path} должен быть действительным адресом электронной почты",
      url: "${path} должен быть действительным URL",
      lowercase: "${path} должен иметь символы только в нижнем регистре",
      uppercase: "${path} должен иметь только заглавные буквы",
    },
    number: {
      min: "${path} должен быть больше или равен ${min}",
      max: "${path} должна быть меньше или равна ${max}",
      lessThan: "${path} должен быть меньше, чем ${less}",
      moreThan: "${path} должен быть больше, чем ${more}",
      positive: "${path} должен быть положительным числом",
      negative: "${path} должен быть отрицательным",
      integer: "${path} должен быть целым числом",
    },
    date: {
      min: "${path} поле должно быть позже, чем ${min}",
      max: "${path} поле должно быть раньше, чем ${max}",
    },
    array: {
      min: "${path} поле должно иметь по крайней мере ${min} элементов",
      max: "${path} поле должно иметь меньше или равна ${max} элементов",
    },
  },

  pl: {
    mixed: {
      default: "${path} jest nieprawidłowy",
      required: "${path} jest polem obowiązkowym",
      oneOf: "${path} musi być jedną z następujących znaków: ${values}",
      notOneOf: "${path} nie może być jedną z następujących znaków: ${values}",
    },
    string: {
      length: "${path} musi mieć dokładnie ${length} znaków",
      min: "${path} musi mieć co najmniej ${min} znaków",
      max: "${path} nie może być dłuższy niż ${max} znaków",
      email: "${path} musi być prawidłowym adresem e-mail",
      url: "${path} musi być poprawnym adresem URL",
      lowercase: "${path} może składać się wyłącznie z małych liter",
      uppercase: "${path} może składać się wyłącznie z wielkich liter",
    },
    number: {
      min: "${path} musi być większa lub równa ${min}",
      max: "${path} musi być mniejsza lub równa ${max}",
      lessThan: "${path} musi być mniejsza niż ${less}",
      moreThan: "${path} musi być większa niż ${more}",
      positive: "${path} musi być liczbą dodatnią",
      negative: "${path} musi być liczbą ujemną",
      integer: "${path} musi być liczbą całkowitą",
    },
    date: {
      min: "${path} musi być późniejsza niż ${min}",
      max: "${path} musi być wcześniejsza niż ${max}",
    },
    array: {
      min: "${path} musi mieć co najmniej ${min} znaków",
      max: "${path} może mieć co najwyżej ${max} znaków",
    },
  },
};
