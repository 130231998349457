import { debounce } from "lodash";
import React from "react";
import { useLatest, useUpdateEffect } from "react-use";

export const useDebouncedValue = <T,>(value: T, ms: number = 500): T => {
  const [state, setState] = React.useState(value);
  const valueLatest = useLatest(value);

  const update = React.useRef(
    debounce(() => {
      setState(valueLatest.current);
    }, ms)
  );

  useUpdateEffect(update.current, [value]);

  return state;
};
