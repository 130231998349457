import { Chip } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CollapseChip = styled(Chip)({
  height: "20px",
  fontWeight: "bold",
  backgroundColor: "unset",
  "& > span": {
    paddingLeft: "6px",
    paddingRight: "6px",
  },
}) as typeof Chip;
