import { TolgeeProvider } from "@tolgee/react";
// Import required Moment.js locales
import "moment/dist/locale/de";
import "moment/dist/locale/en-gb";
import "moment/dist/locale/fr";
import "moment/dist/locale/nl";
import "moment/dist/locale/pl";
import "moment/dist/locale/ru";
import React from "react";
import { tolgee } from "./tolgee";

export function TranslationsProvider({
  children,
}: React.PropsWithChildren<{}>) {
  return (
    <TolgeeProvider tolgee={tolgee} options={{ useSuspense: false }}>
      {children}
    </TolgeeProvider>
  );
}
