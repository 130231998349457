import { Box, BoxProps, CircularProgress } from "@mui/material";

interface Props {
  size?: "s" | "l";
}

export const LoadingSpinner = ({
  size,
  padding = 2,
  ...props
}: Props & BoxProps) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    flex={1}
    padding={padding}
    {...props}
  >
    <CircularProgress size={size === "s" ? 18 : 24} color="inherit" />
  </Box>
);
