import React, { Ref, useImperativeHandle } from "react";

export interface ModalOpenProcessRef {
  open: () => void;
}

interface Props<P extends { handleClose: () => void }> {
  Modal: React.ComponentType<P>;
  modalProps?: Omit<P, "handleClose">;
}

const defaultModalProps = {};

function _ModalOpenProcess<P extends { handleClose: () => void }>(
  { Modal, modalProps = defaultModalProps as Omit<P, "handleClose"> }: Props<P>,
  forwardedRef: Ref<ModalOpenProcessRef | null | undefined>
) {
  const [isOpen, setIsOpen] = React.useState(false);

  useImperativeHandle(forwardedRef, () => ({
    open: () => {
      setIsOpen(true);
    },
  }));

  return isOpen ? (
    // @ts-ignore
    <Modal
      {...modalProps}
      handleClose={() => {
        setIsOpen(false);
      }}
    />
  ) : null;
}

export const ModalOpenProcess = React.memo(React.forwardRef(_ModalOpenProcess));
