import { CreateStyled } from "@emotion/styled";
import { useTheme } from "@mui/material/styles";
import { createMakeAndWithStyles } from "tss-react";

export type { CSSObject } from "tss-react";

// source: https://github.com/emotion-js/emotion/issues/2193#issuecomment-766173118
export const transientOptions: Parameters<CreateStyled>[1] = {
  shouldForwardProp: (propName: string) => !propName.startsWith("$"),
};

// source https://github.com/garronej/tss-react#mui-integration
export const { makeStyles, withStyles } = createMakeAndWithStyles({
  useTheme,
  /*
  OR, if you have extended the default mui theme adding your own custom properties:
  Let's assume the myTheme object that you provide to the <ThemeProvider /> is of
  type MyTheme then you'll write:
  */
  //"useTheme": useTheme as (()=> MyTheme)
});
