import { useMediaQuery, useTheme } from "@mui/material";

interface State {
  isMaxPhone: boolean;
  isMinTablet: boolean;
  isMaxTablet: boolean;
  isMinDesktop: boolean;
  isMaxDesktop: boolean;
  isMinLargeDesktop: boolean;
}

export const useScreenWidth = (): State => {
  const theme = useTheme();
  const isMinTablet = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.tablet),
    { noSsr: true }
  );
  const isMinDesktop = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.desktop),
    { noSsr: true }
  );
  const isMinLargeDesktop = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.xl),
    { noSsr: true }
  );

  return {
    isMaxPhone: !isMinTablet,
    isMinTablet,
    isMaxTablet: !isMinDesktop,
    isMinDesktop,
    isMaxDesktop: !isMinLargeDesktop,
    isMinLargeDesktop,
  };
};
