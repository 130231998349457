import { Chip } from "@mui/material";
import React from "react";
import { ellipsisStyle } from "../text/Ellipsis";

interface Props extends React.ComponentProps<typeof Chip> {
  endIcon?: React.ReactNode;
}

export const StatusChip = React.forwardRef<HTMLDivElement, Props>(
  (
    { endIcon, icon, label, variant = "filled", size = "small", ...props },
    ref
  ) => (
    <Chip
      ref={ref}
      component={"span"}
      icon={icon}
      label={
        endIcon ? (
          <>
            <span style={ellipsisStyle}>{label}</span>
            <span
              style={{
                display: "inline-flex",
                height: "1em",
                alignItems: "center",
                width: "24px",
                marginLeft: "2px",
                marginRight: "-4px",
                alignSelf: "center",
              }}
            >
              {endIcon}
            </span>
          </>
        ) : (
          label
        )
      }
      variant={variant}
      size={size}
      sx={{
        textTransform: "uppercase",
        fontWeight: "normal",
        height: "auto",
        ...ellipsisStyle,
        ".MuiChip-label": {
          display: "inline-flex",
          textAlign: "center",
          fontWeight: 500,
          ...ellipsisStyle,
        },
      }}
      {...props}
    />
  )
);
