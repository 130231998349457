import React from "react";

type Locale = string;

const DEFAULT_LOCALE: Locale = "en-GB";

const LocaleContext = React.createContext<Locale>(DEFAULT_LOCALE);

export const LocaleProvider = LocaleContext.Provider;
export const useLocale = () => React.useContext(LocaleContext);
