import { CircularProgress, Stack, Typography } from "@mui/material";
import { ReactComponent as MSLogoShort } from "./logo-short.svg";
import { color } from "./MuiThemeProvider";
import React from "react";

export const SplashScreen = ({
  message,
  isLoading = true,
  showLogo = false,
  button,
}: {
  message?: string;
  isLoading?: boolean;
  showLogo?: boolean;
  button?: React.ReactElement;
}) => (
  <Stack
    direction="column"
    spacing={2}
    p={1}
    width="100%"
    height="100vh"
    flexGrow={1}
    flexShrink={1}
    justifyContent="center"
    alignItems="center"
  >
    {showLogo && (
      <div style={{ display: "flex", color: color.primary }}>
        <MSLogoShort width="32" height="32" />
      </div>
    )}
    {message && <Typography variant="body1">{message}</Typography>}
    {isLoading && <CircularProgress size={24} color="primary" />}
    {button}
  </Stack>
);
