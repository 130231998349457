import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  IconButton,
  IconButtonProps,
  Stack,
  StackProps,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useScreenWidth } from "../hooks/useScreenWidth";
import { ellipsisStyle } from "../text/Ellipsis";

export type ListHeaderElementsOrder =
  | "search-field-first"
  | "search-field-last"
  | "separate-rows";

export interface ListHeaderProps {
  /**
   * Whether title should not be visible on mobile
   */
  hideTitleOnMobile?: boolean;
  /**
   * Whether title should only take one line
   */
  restricted?: boolean;
  /**
   * Whether component has an expandable button
   */
  isExpandable?: boolean;
  /**
   * Flag controlling expanded state
   */
  expanded?: boolean;
  /**
   * On expanded change callback
   */
  onExpandedChange?: (expanded: boolean) => void;
  /**
   * List header title
   */
  title?: React.ReactNode;
  /**
   * List header title variant
   */
  titleVariant?: React.ComponentProps<typeof Title>["titleVariant"];
  /**
   * Order of elements
   */
  elementsOrder?: ListHeaderElementsOrder;
  /**
   * Create button element
   */
  CreateButton?: JSX.Element;
  /**
   * Menu button element
   */
  MenuButton?: JSX.Element;
  /**
   * Quick filter element
   */
  QuickFilter?: JSX.Element;
  /**
   * Search field element
   */
  SearchField?: JSX.Element;
  /**
   * Additional filter fields
   */
  FilterFields?: JSX.Element;
  /**
   * Filter button element
   */
  FilterButton?: JSX.Element;
  InlineFilters?: JSX.Element;
  ResetApplyButtons?: JSX.Element;
  /**
   * Switch view button element
   */
  SwitchViewButton?: JSX.Element;
  /**
   * Filter chips element
   */
  FilterChips?: JSX.Element;
  /**
   * Action buttons element
   */
  ActionButtons?: JSX.Element;
  /**
   * Information like entity number
   */
  Info?: React.ReactNode;
}

export const ListHeader = ({
  elementsOrder = "search-field-last",
  hideTitleOnMobile = false,
  restricted,
  isExpandable,
  expanded,
  onExpandedChange,
  title,
  titleVariant,
  CreateButton,
  MenuButton,
  QuickFilter,
  FilterButton,
  FilterChips,
  InlineFilters,
  ResetApplyButtons,
  SearchField,
  FilterFields,
  SwitchViewButton,
  ActionButtons,
  Info,
  ...props
}: ListHeaderProps & Omit<StackProps, "title" | "children">) => {
  const { isMaxPhone } = useScreenWidth();

  // const searchRef = React.useRef<HTMLInputElement>(null);
  // const [searchVisible, setSearchVisible] = React.useState<boolean>(false);
  // const toggleSearchVisible = () => {
  //   if (!searchVisible) {
  //     setSearchVisible(true);
  //     setTimeout(() => {
  //       searchRef.current?.focus();
  //     });
  //   } else {
  //     setSearchVisible(false);
  //   }
  // };

  if (isMaxPhone)
    return (
      <ListHeaderMobile
        elementsOrder={elementsOrder}
        hideTitleOnMobile={hideTitleOnMobile}
        restricted={restricted}
        isExpandable={isExpandable}
        expanded={expanded}
        onExpandedChange={onExpandedChange}
        title={title}
        titleVariant={titleVariant}
        CreateButton={CreateButton}
        MenuButton={MenuButton}
        QuickFilter={QuickFilter}
        FilterButton={FilterButton}
        FilterChips={FilterChips}
        InlineFilters={InlineFilters}
        ResetApplyButtons={ResetApplyButtons}
        SearchField={SearchField}
        SwitchViewButton={SwitchViewButton}
        ActionButtons={ActionButtons}
        Info={Info}
        FilterFields={FilterFields}
        {...props}
      />
    );

  const showFilterButtonFirstLine = !QuickFilter && !SearchField;

  const secondLine =
    QuickFilter ||
    (FilterButton && !showFilterButtonFirstLine) ||
    SearchField ||
    FilterFields ||
    Info ? (
      <Stack
        direction={"row"}
        spacing={1}
        justifyContent="flex-start"
        alignItems="center"
        flex={1}
      >
        {elementsOrder === "separate-rows" ? (
          <>{SearchField ? SearchField : null}</>
        ) : elementsOrder === "search-field-last" ? (
          <>
            {QuickFilter}
            {!showFilterButtonFirstLine && FilterButton}
            {SearchField ? SearchField : null}
            {FilterFields}
            {Info && (
              <Stack
                direction={"row"}
                spacing={1}
                flex={1}
                justifyContent={"flex-end"}
              >
                {Info}
              </Stack>
            )}
          </>
        ) : elementsOrder === "search-field-first" ? (
          <>
            {SearchField ? SearchField : null}
            {FilterButton}
            {QuickFilter}
            {FilterFields}
            {Info && (
              <Stack
                direction={"row"}
                spacing={1}
                flex={1}
                justifyContent={"flex-end"}
              >
                {Info}
              </Stack>
            )}
          </>
        ) : null}
      </Stack>
    ) : null;
  const thirdLine =
    elementsOrder === "separate-rows" ? (
      QuickFilter || FilterButton || ResetApplyButtons ? (
        <Stack
          direction={"row"}
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          flex={1}
        >
          <Stack direction={"row"} spacing={1} alignItems="center">
            {QuickFilter}
            {FilterButton}
          </Stack>
          {ResetApplyButtons}
        </Stack>
      ) : null
    ) : ResetApplyButtons ? (
      <Stack
        direction={"row"}
        spacing={1}
        justifyContent="stretch"
        alignItems="center"
        flex={1}
      >
        {ResetApplyButtons}
      </Stack>
    ) : null;
  const chipsLine = FilterChips ? <Box>{FilterChips}</Box> : null;

  return (
    <Stack direction={"column"} spacing={1} width="100%" {...props}>
      <Stack
        direction={"row"}
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" flex={1}>
          {title ? (
            <Title
              restricted={restricted}
              isExpandable={isExpandable}
              expanded={expanded}
              onExpandedChange={onExpandedChange}
              title={title}
              titleVariant={titleVariant}
            />
          ) : (
            secondLine
          )}
        </Box>
        <Stack
          direction={"row"}
          spacing={1}
          minHeight="30px"
          justifyContent="flex-end"
          alignItems="center"
          alignSelf="flex-start"
        >
          {showFilterButtonFirstLine && FilterButton}
          {SwitchViewButton}
          {MenuButton}
          {ActionButtons}
          {CreateButton}
        </Stack>
      </Stack>
      {title ? secondLine : null}
      {InlineFilters}
      {thirdLine}
      {chipsLine}
    </Stack>
  );
};

const ListHeaderMobile = ({
  elementsOrder = "search-field-last",
  hideTitleOnMobile = false,
  restricted,
  isExpandable,
  expanded,
  onExpandedChange,
  title,
  titleVariant,
  CreateButton,
  MenuButton,
  QuickFilter,
  FilterButton,
  FilterChips,
  InlineFilters,
  ResetApplyButtons,
  SearchField,
  SwitchViewButton,
  ActionButtons,
  Info,
  FilterFields,
  ...props
}: ListHeaderProps & Omit<StackProps, "title" | "children">) => {
  const [searchVisible, setSearchVisible] = React.useState<boolean>(false);

  const searchRef = React.useRef<HTMLInputElement>(null);

  const toggleSearchVisible = () => {
    if (!searchVisible) {
      setSearchVisible(true);
      setTimeout(() => {
        searchRef.current?.focus();
      });
    } else {
      setSearchVisible(false);
    }
  };

  const hideTitle =
    !title ||
    (hideTitleOnMobile && (QuickFilter || SearchField || FilterButton));

  const showSearchFieldBlock = hideTitle && !QuickFilter;
  const showSearchButtonInline = !QuickFilter && !showSearchFieldBlock;
  const showSearchButtonBlock = QuickFilter && !showSearchFieldBlock;
  const showFilterButtonFirstLine =
    !QuickFilter &&
    !(SearchField && showSearchFieldBlock) &&
    !(SearchField && showSearchButtonBlock && !searchVisible);

  const titleLine = !hideTitle ? (
    <Title
      restricted={restricted}
      isExpandable={isExpandable}
      expanded={expanded}
      onExpandedChange={onExpandedChange}
      title={title}
      titleVariant={titleVariant}
    />
  ) : null;

  const rightButton =
    CreateButton ||
    MenuButton ||
    ActionButtons ||
    SwitchViewButton ||
    (SearchField && showSearchButtonInline && !searchVisible) ? (
      <Stack
        direction={"row"}
        spacing={1}
        minHeight="30px"
        justifyContent="flex-end"
        alignItems="center"
        alignSelf="flex-start"
      >
        {MenuButton}
        {ActionButtons}
        {showFilterButtonFirstLine && FilterButton}
        {SearchField && showSearchButtonInline && !searchVisible ? (
          <SearchButton onClick={toggleSearchVisible} />
        ) : null}
        {SwitchViewButton}
        {CreateButton}
      </Stack>
    ) : null;

  const secondLine =
    QuickFilter ||
    (FilterButton && !showFilterButtonFirstLine) ||
    (SearchField && showSearchFieldBlock) ||
    (SearchField && showSearchButtonBlock && !searchVisible) ? (
      <Stack
        direction={"row"}
        spacing={1}
        justifyContent="flex-start"
        alignItems="center"
        flex={1}
      >
        {elementsOrder === "search-field-last" ? (
          <>
            {QuickFilter}
            {!showFilterButtonFirstLine && FilterButton}
            {SearchField && showSearchFieldBlock ? SearchField : null}
            {SearchField && showSearchButtonBlock && !searchVisible ? (
              <SearchButton onClick={toggleSearchVisible} />
            ) : null}
          </>
        ) : elementsOrder === "search-field-first" ||
          elementsOrder === "separate-rows" ? (
          <>
            {SearchField && showSearchButtonBlock && !searchVisible ? (
              <SearchButton onClick={toggleSearchVisible} />
            ) : null}
            {SearchField && showSearchFieldBlock ? SearchField : null}
            {FilterButton}
            {QuickFilter}
          </>
        ) : null}
      </Stack>
    ) : null;

  const thirdLine =
    (SearchField && searchVisible) || Info || FilterFields ? (
      <Stack direction={"column"} spacing={1} width="100%">
        {SearchField && searchVisible ? (
          <Stack
            direction={"row"}
            spacing={1}
            width="100%"
            alignItems="center"
            flex={1}
          >
            {React.cloneElement(SearchField, {
              inputRef: searchRef,
              fullWidth: true,
            })}
            <CloseButton onClick={toggleSearchVisible} />
          </Stack>
        ) : null}
        {FilterFields ? (
          <Stack direction={"row"} spacing={1} alignItems="center">
            {FilterFields}
          </Stack>
        ) : null}
        {Info ? (
          <Stack direction={"row"} spacing={1} alignItems="center">
            {Info}
          </Stack>
        ) : null}
      </Stack>
    ) : null;

  const chipsLine = FilterChips ? <Box>{FilterChips}</Box> : null;

  return (
    <Stack direction={"column"} spacing={1} width="100%" {...props}>
      <Stack
        direction={"row"}
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" flex={1}>
          {titleLine ?? secondLine ?? thirdLine}
        </Box>
        {rightButton}
      </Stack>
      {titleLine ? secondLine ?? thirdLine : null}
      {secondLine ? thirdLine : null}
      {chipsLine}
    </Stack>
  );
};

const BoxButton = styled(Box)`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.palette.primary.light};
  }
`;
const StyledTitle = styled(Typography)`
  margin: 0;
  padding: 0;
`;

const Title = ({
  restricted = false,
  isExpandable = false,
  expanded = false,
  onExpandedChange,
  title = "",
  titleVariant = "h1",
}: {
  restricted?: boolean;
  isExpandable?: boolean;
  expanded?: boolean;
  onExpandedChange?: (expanded: boolean) => void;
  title?: React.ReactNode;
  titleVariant?: React.ComponentProps<typeof Typography>["variant"];
}) =>
  isExpandable ? (
    <BoxButton
      alignItems="center"
      display="flex"
      onClick={() => onExpandedChange?.(!expanded)}
    >
      <IconButton size="small" style={{ marginRight: 2 }}>
        {expanded ? (
          <ArrowDropDownIcon color="primary" />
        ) : (
          <ArrowRightIcon color="primary" />
        )}
      </IconButton>
      <StyledTitle
        variant={titleVariant}
        style={restricted ? ellipsisStyle : undefined}
      >
        {title}
      </StyledTitle>
    </BoxButton>
  ) : (
    <StyledTitle
      variant={titleVariant}
      style={restricted ? ellipsisStyle : undefined}
    >
      {title}
    </StyledTitle>
  );

const SearchButton = (props: IconButtonProps) => (
  <IconButton
    style={{ flexShrink: 0, flexGrow: 0, width: 30, height: 30 }}
    size="small"
    color="primary"
    {...props}
  >
    <SearchIcon />
  </IconButton>
);

const CloseButton = (props: IconButtonProps) => (
  <IconButton
    style={{ flexShrink: 0, flexGrow: 0, width: 30, height: 30 }}
    size="small"
    color="primary"
    {...props}
  >
    <CloseIcon />
  </IconButton>
);
