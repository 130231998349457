import { Collapse, Paper } from "@mui/material";
import isUndefined from "lodash.isundefined";
import React from "react";
import { useUpdateEffect } from "react-use";
import { CollapseHeader } from "./CollapseHeader";
import { CollapseTitle } from "./CollapseTitle";
import { LoadingSpinner } from "./loading/LoadingSpinner";

interface Props {
  id?: string;
  isExpandable?: boolean;
  Icon?: React.ReactElement;
  title?: React.ReactNode;
  tabs?: React.ReactElement;
  isInitiallyClosed?: boolean;
  itemCount?: number;
  CollapsedHint?: React.ReactNode;
  ActionButton?: React.ReactNode;
  isSelected?: boolean;
  disableAutoExpand?: boolean;
  isLoading?: boolean;
}

export const CardContainer = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<Props>
>(
  (
    {
      id,
      Icon,
      title,
      tabs,
      isExpandable: isExpandableProp,
      isInitiallyClosed,
      CollapsedHint,
      itemCount,
      ActionButton,
      isSelected = false,
      disableAutoExpand = false,
      isLoading = false,
      children,
    },
    ref
  ) => {
    const isExpandable = isExpandableProp ?? itemCount !== undefined;

    const [isExpanded, setIsExpanded] = React.useState(
      !isExpandable
        ? true
        : !isUndefined(isInitiallyClosed)
        ? !isInitiallyClosed
        : !isUndefined(itemCount)
        ? itemCount && itemCount > 0
          ? true
          : false
        : true
    );

    useUpdateEffect(() => {
      if (!isExpandable || disableAutoExpand) return;

      if (itemCount && itemCount > 0) {
        setIsExpanded(true);
      } else if (itemCount === 0) {
        setIsExpanded(false);
      }
    }, [itemCount]);

    const Title = title ? (
      <CollapseTitle title={title} icon={Icon} count={itemCount} />
    ) : undefined;

    return (
      <Paper
        id={id}
        ref={ref}
        sx={isSelected ? { boxShadow: "inset 0 0 0 2px #377dc3" } : {}}
      >
        {Title || tabs ? (
          <CollapseHeader
            isExpandable={isExpandable}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            title={Title}
            tabs={tabs}
            ActionButton={ActionButton}
            CollapsedHint={CollapsedHint}
            px={1}
          />
        ) : null}
        {isLoading ? (
          isExpanded && <LoadingSpinner padding={1} />
        ) : (
          <Collapse in={isExpanded}>{children}</Collapse>
        )}
      </Paper>
    );
  }
);
