type GraphqlData = {
  operationName: string;
  variables: Record<string, any>;
};

type TrackableEvent =
  //
  | { eventName: "SelectProduct"; data: Record<string, any> }
  | { eventName: "UnselectProduct"; data: Record<string, any> }
  | { eventName: "AddSelectedProducts"; data: Record<string, any> }
  //
  | { eventName: "AddProduct"; data: Record<string, any> }
  //
  | { eventName: "graphql_query"; data: GraphqlData }
  | { eventName: "graphql_mutation"; data: GraphqlData }
  | { eventName: "graphql_subscription"; data: GraphqlData };

const ORIENTATIONS: Record<string, OrientationType> = {
  "-90": "landscape-secondary",
  "0": "portrait-primary",
  "90": "landscape-primary",
  "180": "portrait-secondary",
};

export async function track(obj: TrackableEvent) {
  if (obj.eventName === "graphql_query") {
    if (obj.data.operationName !== "ProductSearchModal") return;
  }

  let newData = {
    ...obj.data,
    $$context: {
      href: window.location.href,
      host: window.location.host,
      pathname: window.location.pathname,
      language: navigator.language,
      userAgent: navigator.userAgent,
      device: {
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
        availHeight: window.screen.availHeight,
        availWidth: window.screen.availWidth,
        height: window.screen.height,
        width: window.screen.width,
        devicePixelRatio: window.devicePixelRatio,
        orientation:
          window.screen?.orientation?.type ?? ORIENTATIONS[window.orientation],
      },
    },
  };

  if (
    obj.eventName === "graphql_mutation" ||
    obj.eventName === "graphql_query" ||
    obj.eventName === "graphql_subscription"
  ) {
    (obj.eventName as string) = `${obj.eventName}:${obj.data.operationName}`;
  }

  await fetch("/api/track", {
    method: "POST",
    body: JSON.stringify({
      eventName: obj.eventName,
      data: newData,
    }),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
}
